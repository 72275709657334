/* eslint-disable no-use-before-define */

import PeliasResult from './result';

const PeliasRequest = function (options) {
  const autoCompleteEndpoint = 'v1/autocomplete';
  const reverseEndpoint = 'v1/reverse';
  options = options || {};
  const baseUrl = options.baseUrl || 'https://api.geocode.earth/';
  const limit = options.limit;
  const layers = options.locationType || 'coarse';

  this.autoComplete = function (term, callback) {
    const params = {
      text: term,
    };
    call(autoCompleteEndpoint, params, callback);
  };

  this.reverseGeocode = function (lat, lng, callback) {
    const params = {
      'point.lat': lat,
      'point.lon': lng,
    };
    call(reverseEndpoint, params, callback);
  };

  function resultsFrom(data) {
    return $.map(data.features, function (datum) {
      return new PeliasResult(datum);
    });
  }

  function call(endpoint, params, callback) {
    $.ajax({
      url: url(endpoint, requestParams(params)),
      success: function (data) {
        callback(resultsFrom(data));
      },
      error: function () {
        callback([]);
      },
    });
  }

  function url(endpoint, params) {
    return baseUrl + endpoint + '?' + params;
  }

  function requestParams(params) {
    const instanceParams = {
      api_key: window.LocationControl.Providers.Pelias.apiKey,
      layers: layers,
      size: limit,
    };
    params = $.extend({}, instanceParams, params);

    return $.param(params);
  }
};

export default PeliasRequest;
