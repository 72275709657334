/**
 *  Uses the chosen jQuery plugin on a <select>.
 *  The select dropdown updates automatically with the list you pass in to chosen.
 *
 *  Example:
 *  <select chosen="hiringPlans" ng-modal="hiringPlan.selected" ng-options="hiringPlan.name for hiringPlan in hiringPlans track by hiringPlan.id"></select>
 */
import { IAttributes, IScope, ITimeoutService } from 'angular';

interface Attributes extends IAttributes {
  disableSearch: boolean | string;
}

export default function chosen($timeout: ITimeoutService) {
  return {
    restrict: 'A',
    scope: {
      ngDisabled: '=',
      chosen: '=',
      ngModel: '=',
    },
    link: function (scope: IScope, element: any, attrs: Attributes) {
      scope.$watch('chosen', triggerUpdate);
      scope.$watch('ngDisabled', triggerUpdate);
      scope.$watch('ngModel', triggerUpdate);

      element.chosen({
        disable_search: !!attrs.disableSearch,
        search_contains: true,
      });

      function triggerUpdate() {
        $timeout(function () {
          element.trigger('liszt:updated');
        }, 1);
      }
    },
  };
}
