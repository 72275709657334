import { IAttributes, IScope } from 'angular';

export default function ghNaturalNumbersOnly() {
  return {
    require: '?ngModel',
    link: function (
      scope: IScope,
      element: any,
      attrs: IAttributes,
      ngModelCtrl: any
    ) {
      if (!ngModelCtrl) {
        return;
      }

      ngModelCtrl.$parsers.push(function (val: string) {
        var clean = val.replace(/[^0-9]+/g, '');
        if (val !== clean) {
          ngModelCtrl.$setViewValue(clean);
          ngModelCtrl.$render();
        }
        return clean;
      });

      element.bind('keypress', function (event: any) {
        if (event.keyCode === 32) {
          event.preventDefault();
        }
      });
    },
  };
}
