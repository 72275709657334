/**
 * Attribute-level directive that can lock the element, preventing further clicks, until some
 * action is completed.  Example:
 *
 * <button gh-click-and-lock="controller.doWork()"></button>
 *
 * Once the button is pressed, controller.doWork() is invoked and the button receives the
 * 'locked' class.  The button will ignore any future click events until controller.doWork()
 * finishes.
 *
 * NOTE: the function passed to gh-click-and-lock MUST return a Promise object.
 */ import { IPromise, IScope } from 'angular';

interface ClickAndLockScope extends IScope {
  locked: boolean;
  ghClickAndLock: () => IPromise<any>;
}

export default function ghClickAndLock() {
  function link(scope: ClickAndLockScope, $element: any) {
    scope.locked = false;

    $element.on('click', function () {
      if (scope.locked) {
        return;
      }

      scope.locked = true;
      $element.addClass('locked');

      scope.ghClickAndLock().finally(function () {
        scope.locked = false;
        $element.removeClass('locked');
      });
    });
  }

  return {
    scope: {
      ghClickAndLock: '&',
    },
    restrict: 'A',
    link: link,
  };
}
