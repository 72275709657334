import Ajax from 'legacy/ajax';
import $ from 'legacy/jquery';

const HiringPlanOpenings = (function () {
  function registerEvents() {
    const toggle = $('#use_req_ids');
    if (toggle.length > 0) {
      toggle.on('change', toggleIds);
      toggleIds.call(toggle);
    }

    $('#generate_req_ids').on('click', generateIds);
    $('#number_of_openings').on('change', refreshOpenings).change();
    registerDuplicateCheck(
      $('#opening_ids').find('input[type=text]'),
      $('#opening_ids').attr('data-dupe-path')
    );
    // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
    registerDuplicateCheck($('#requisition_id'));
  }

  function addWarningDiv(input: any, msg: string) {
    const container = $(input).parent();

    if (container.find('.dupe-id-warning').length > 0) {
      return;
    }

    const warning = $('<div/>')
      .addClass('field-warning-msg')
      .addClass('dupe-id-warning')
      .text(msg)
      .hide();

    container.append(warning);
  }

  // @ts-expect-error - TS7006 - Parameter 'checkUrl' implicitly has an 'any' type.
  function registerDuplicateCheck(input: any, checkUrl) {
    checkUrl = checkUrl || $(input).attr('data-dupe-path');

    addWarningDiv(input, 'Note: Another Job has this ID');
    $(input).attr('data-dupe-original-value', input.val());

    $(input).typeWatch({
      wait: 500,
      captureLength: 1,
      // @ts-expect-error - TS7006 - Parameter 'id' implicitly has an 'any' type.
      callback: function (id) {
        if (id == $(this).attr('data-dupe-original-value')) {
          $(this).parent().find('.dupe-id-warning').hide();
          return;
        }

        Ajax.get({
          url: checkUrl,
          data: { id: id },
          pendingMessage: 'Checking for duplicate ID',
          success: function (response: any) {
            // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
            const container = $(this).parent().find('.dupe-id-warning');
            response.exists ? container.show() : container.hide();
          }.bind(this),
        });
      },
    });
  }

  function toggleIds() {
    // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
    const checked = $(this).is(':checked');
    const reqIds = $('.req-ids');

    reqIds.toggle(checked);

    if (checked) {
      $(reqIds[0]).find('input[type=text]').focus();
    }
  }

  function refreshOpenings() {
    const openingIds = $('#opening_ids');
    const currentCount = openingIds.find('li').length;
    // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
    const newCount = parseInt($(this).val(), 10);
    const hrisOpeningIdValues = $('#hris_opening_ids').data('value') || [];
    let i;

    if (newCount > currentCount) {
      for (i = currentCount; i < newCount; i++) {
        const input = $('<input/>')
          .attr('type', 'text')
          .attr('name', 'opening_ids[]');
        if (hrisOpeningIdValues.length > 0 && i in hrisOpeningIdValues) {
          input.val(hrisOpeningIdValues[i]);
        }
        const li = $('<li/>').append(input);

        registerDuplicateCheck(input, openingIds.attr('data-dupe-path'));
        openingIds.append(li);
      }
    } else if (newCount < currentCount) {
      for (i = currentCount; i >= newCount; i--) {
        $(openingIds.find('li')[i]).remove();
      }
    }

    openingIds.trigger('greenhouse.heightChanged');
  }

  function generateIds() {
    const path =
      // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
      $(this).data('path') + '?openings=' + $('#number_of_openings').val();

    Ajax.post<{ requisition_id: string }>({
      url: path,
      pendingMessage: 'Loading...',
      success: function (response) {
        $('#requisition_id').val(response?.requisition_id);

        $('#opening_ids')
          .find('input[type=text]')
          // @ts-expect-error - TS7006 - Parameter 'i' implicitly has an 'any' type.
          .each(function (i) {
            // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
            $(this).val(response.opening_ids[i]);
          });
      },
    });
  }

  function registerOpeningDropdown(base: string) {
    const $opening = $('#number_of_openings');
    const $label = $(base + ' .close-reason-label');
    const $dropdown = $(base + ' #s2id_close_reason_id');
    const initialOpeningsValue = parseInt($opening.val(), 10);

    $opening.on('change', toggleCloseReasonDropdown);

    function toggleCloseReasonDropdown() {
      // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
      const selectedValue = parseInt($(this).val(), 10);

      if (selectedValue < initialOpeningsValue) {
        $label.show();
        $dropdown.show();
      } else {
        $label.hide();
        $dropdown.hide();
      }
    }
  }

  return {
    init: registerEvents,
    registerOpeningDropdown: registerOpeningDropdown,
  };
})();

export default HiringPlanOpenings;
