/**
 * Manages tab-based navigation for a given page.  To use it, the page needs:
 *
 *   * A place on the page that holds the tab handles, used for navigation. This must be structured like:
 *
 *     <div class='gh-tabs'>
 *       <div class='tabs-nav'>
 *         <ul>
 *           <li>
 *             <a href='#tab1' data-tab-path='/path/to/tab1/contents'>Tab 1</a>
 *           </li>
 *           <li>
 *             <a href='#tab2' data-tab-path='/path/to/tab2/contents'>Tab 2</a>
 *           </li>
 *         </ul>
 *       </div>
 *     </div>
 *
 *   * A place on the page where the actual tab content will be shown.  Note that each tab has its own
 *     separate div!  The div's ID corresponds to the anchor for each tag.  Example:
 *
 *   <div id="tab1_tab"></div>
 *   <div id="tab2_tab"></div>
 *
 *   * To initialize:
 *
 *      var tabs = new Tabs('.gh-tabs');
 *      tabs.init();
 */

/*
  eslint-disable no-use-before-define
 */

// eslint-disable-next-line no-restricted-imports
import _ from 'underscore';
import Ajax from 'legacy/ajax';
import $ from 'legacy/jquery';
import Message from 'legacy/message';

const Tabs = function (selector) {
  let $tabNavigation;
  const tabs = {};

  function init() {
    $tabNavigation = $(selector);
    $tabNavigation.find('.tabs-nav li').each(function (i, li) {
      const $link = $(li).find('a');
      const tabId = $link.attr('href').split('#')[1];

      tabs[tabId] = new Tab(tabId, $link);
    });

    const defaultTab = tabFromAnchor() || currentlySelectedTab();
    showTab(defaultTab);

    $(window).bind('hashchange', function () {
      showTab(tabFromAnchor());
    });
  }

  function deselectAllTabs() {
    _(tabs).each(function (tab) {
      tab.hide();
    });
  }

  function showTab(tab) {
    if (tab) {
      deselectAllTabs();
      tab.show();
      return true;
    }
  }

  function tabFromAnchor() {
    let tabId = window.location.hash.slice(1);

    if (tabId.length > 1) {
      // Remove the leading forward slash added to the anchor by angular JS
      tabId = tabId.replace('/', '');

      return tabs[tabId];
    }

    return null;
  }

  // Returns the tab that is currently selected in the GUI nav, i.e. has the
  // "selected" class. The tab contents aren't necessarily visible, though.
  function currentlySelectedTab() {
    return _(tabs).find(function (tab) {
      return tab.isSelected();
    });
  }

  return {
    init: init,
  };
};

/**
 * Represents a single tab on the page.  A tab has UI components:
 *  * The 'handle', or the little tab itself that the user clicks on to select the tab
 *  * The 'content pane', which displays the data for this tab.
 *
 * Only a single tab can be active at any given time.  Selecting a new tab implicitly deselects all of
 * the other tabs
 *
 * @param tabId Corresponds to the 'anchor' used to display this tab (e.g. 'candidate_details', 'private')
 * @param $link The link the user clicks on to select the tab
 * @constructor
 */
function Tab(tabId, $link) {
  const $contentPane = $('#' + tabId + '_tab');
  let loaded = $link.data('loaded') || false;
  const url = $link.data('tab-path');

  init();

  function init() {
    $contentPane.on('greenhouse.refresh', refresh);
  }

  function id() {
    return tabId;
  }

  function refresh() {
    loaded = false;
    if (isSelected()) {
      show();
    }
  }

  function show() {
    if (url && !loaded) {
      loadContent();
      loaded = true;
    }

    $link.parents('li').addClass('selected');
    $contentPane.show();
    $contentPane.trigger('greenhouse.tabSelected');
  }

  function hide() {
    $link.parents('li').removeClass('selected');
    $contentPane.hide();
  }

  function loadContent() {
    Ajax.get({
      url: url,
      dataType: 'html',
      disableFlash: true,
      success: onLoad,
      error: showError,
    });

    function onLoad(response) {
      $contentPane.html(response);
      $contentPane.trigger('greenhouse.loaded');
    }

    function showError() {
      $contentPane.html(Message.Error.TabContent);
    }
  }

  function isSelected() {
    return $link.parents('li').hasClass('selected');
  }

  return {
    id: id,
    show: show,
    hide: hide,
    isSelected: isSelected,
  };
}

export default Tabs;
