import { IAttributes, IScope } from 'angular';

interface Attributes extends IAttributes {
  ghEnter: any;
}

export default function ghEnter() {
  return function (scope: IScope, element: any, attrs: Attributes) {
    element.on('keydown keypress', function (event: any) {
      if (event.which === 13) {
        scope.$apply(function () {
          scope.$eval(attrs.ghEnter);
        });

        event.preventDefault();
      }
    });
  };
}
