/**
 * Functions is a case-insensitive version of the jQuery "contains" selector.  Usage is the same
 * as the contains selector
 * <code>
 *   li:containsInsensitive('abcd')
 * </code>
 * will return true for ABCD or AbCd.
 *
 * Reference: https://css-tricks.com/snippets/jquery/make-jquery-contains-case-insensitive/
 * @param a
 * @param i
 * @param m
 * @returns {boolean}
 */

window.jQuery.expr[':'].containsInsensitive = function (a, i, m) {
  return jQuery(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
};
