import $ from './jquery';

export const onEnterKey = function (
  elem: string | JQuery,
  listener: (e: KeyboardEvent) => void
) {
  $(elem).on('keydown', function (e: KeyboardEvent) {
    const ENTER_KEY_CODE = 13;
    if (e.which === ENTER_KEY_CODE) {
      return listener(e);
    }
  });
};
