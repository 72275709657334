import _ from 'underscore';
import $ from 'legacy/jquery';
import Modal from 'legacy/modal';
// @ts-expect-error - TS7016
import Routes from 'legacy/routes';

/**
 * See taggable.rb for detailed end-to-end instructions on adding tagging to your model.
 *
 * init()
 *   Initializes all textareas that have the class 'taggable' to support tagging. You must have a
 *   data-with-tags attribute if you wish to initialize the textarea with already-marked-up text.
 *   Note that this method returns false if tagging is unsupported (older versions of IE).
 *
 *   Also initializes the @mentions modal if present on the page.
 *
 * prepareSubmit()
 *   You must call this right before your form is submitted, to add (or update) hidden fields
 *   containing marked-up copies of the text.
 */
const Taggable = (function () {
  const SELECTOR = 'textarea.taggable';
  let modal: any = null;
  let cachedUsers: any = null;

  function normalizeName(name: string) {
    return name.toLowerCase().replace(/\W/g, '');
  }

  function normalizeUsers(users: Array<any>) {
    _.each(users, function (user) {
      user.normalizedName = normalizeName(user.name);
    });
  }

  function init() {
    if (!modal) {
      modal = new Modal('#mentions_modal', {
        openWith: '#mentions',
        config: { width: 600 },
      });
    }

    const textareas = $(SELECTOR);
    let ajaxRequest: any = null;

    textareas.textntags({
      triggers: {
        '@': {
          minChars: 0,
          // this is overridden to add single quote to the characters allowed in a tag name
          parser:
            /(@)\[\[(\d+):([\w\s\.\-]+):([\w\s@\.,-\/#!$%\^&\*;:{}=\-_`~()']+)\]\]/gi,
        },
      },

      realValOnSubmit: false,

      onDataRequest: function (
        _mode: any,
        query: string,
        triggerChar: string,
        callback: (this: any, filteredResults: Array<any>) => void
      ) {
        if (ajaxRequest) {
          ajaxRequest.abort();
        }

        if (cachedUsers) {
          callback.call(this, filter(cachedUsers, query));
        } else {
          ajaxRequest = $.getJSON(
            Routes.tags_path,
            function (this: any, response: any) {
              normalizeUsers(response);
              cachedUsers = response;
              callback.call(this, filter(cachedUsers, query));
              ajaxRequest = null;
            }
          );
        }
      },
    });

    textareas.each(function (this: any) {
      const textarea = $(this);
      const taggedValue = textarea.data('with-tags');
      const minHeight = textarea.css('min-height');

      if (minHeight) {
        textarea.siblings('.textntags-beautifier').css('min-height', minHeight);
      }

      textarea.textntags('val', taggedValue || textarea.val());
    });

    return true;
  }

  function prepareSubmit() {
    $(SELECTOR).each(function (this: any) {
      const textarea = $(this);
      let hiddenField = textarea.next('input[type=hidden]');

      if (hiddenField.length === 0) {
        hiddenField = createHiddenFieldFor(textarea);
      }

      hiddenField.val(markupFor(textarea));
    });
  }

  function createHiddenFieldFor(textarea: any) {
    const name = textarea.attr('name');
    const qualified = name.match(/\]$/);
    const field = qualified ? name.match(/\[(.+?)\]$/)[1] : name;
    const newField = field + '_with_tags';
    const newName = qualified
      ? name.replace('[' + field + ']', '[' + newField + ']')
      : newField;
    const hiddenField = $('<input/>')
      .attr('type', 'hidden')
      .attr('name', newName);

    if (textarea.attr('id')) {
      hiddenField.attr('id', textarea.attr('id') + '_with_tags');
    }

    hiddenField.insertAfter(textarea);
    return hiddenField;
  }

  // Clears a taggable textarea.
  function clear(selector: string) {
    const textarea = $(selector);

    textarea.textntags('val', '');
  }

  function latestFields() {
    const fields: {
      [name: string]: { plaintext: string; markup: string; textarea: any };
    } = {};

    $(SELECTOR).each(function (this: any) {
      const textarea = $(this);
      const name = textarea.attr('name');

      fields[name] = {
        plaintext: textarea.val(),
        markup: markupFor(textarea),
        textarea: textarea,
      };
    });

    return fields;
  }

  function markupFor(textarea: any) {
    let result = '';

    textarea.textntags('val', function (markup: string) {
      // optimization: don't store marked-up text if there is no markup in it
      result = markup === textarea.val() ? '' : markup;
    });

    return result;
  }

  function escapeRegex(str: string) {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  function createFilterRegex(query: string) {
    query = query.replace(/\s+/, ' ').trim();
    let parts = query.split(' ');
    parts = _.map(parts, function (part) {
      const result = normalizeName(part);
      return escapeRegex(result);
    });
    return parts.join('.*');
  }

  function filter(users: Array<any>, query: string) {
    query = createFilterRegex(query);

    const matches = _(users).filter(function (user) {
      return user.normalizedName.search(query) > -1;
    });

    return _(matches).first(10);
  }

  return {
    init: init,
    prepareSubmit: prepareSubmit,
    clear: clear,
    latestFields: latestFields,
  };
})();

export default Taggable;
