/* eslint-disable no-use-before-define */

const Control = function (provider, $element, onLocationSelect) {
  const fields = [];
  const fieldNames = [
    'city',
    'country_long_name',
    'country_short_name',
    'latitude',
    'longitude',
    'state_short_name',
    'state_long_name',
    'postal_code',
  ];

  onLocationSelect = onLocationSelect || function () {};

  // Proxy these to the provider
  this.geocode = provider.geocode;
  this.reverseGeocode = provider.reverseGeocode;

  this.getElement = function () {
    return $element;
  };

  this.resultSelected = function (result) {
    provider.resultSelected(result, this.updateDataElement);
  };

  this.updateDataElement = function (location) {
    $.each(fields, function (_i, $field) {
      $field.val(location[$field.data('locationField')]);
    });

    $element.addClass('ran'); // Test hook
    $element.data('valid', true);
    $element.val(location.name);
    $element.blur();

    onLocationSelect(location);
  };

  function init() {
    initializeDataElements();
    cleanOnEdit();
  }

  function hiddenInputField(suffix) {
    const className = 'location-' + suffix.replace(/_/g, '-');
    const name = extractBaseName() + '[' + suffix + ']';
    let $hiddenInput = $('[name="' + name + '"]');

    if ($hiddenInput.length === 0) {
      $hiddenInput = $('<input />').attr('type', 'hidden').attr('name', name);
    }

    $hiddenInput.data('locationField', suffix).addClass(className);

    return $hiddenInput;
  }

  function clean() {
    $.each(fields, function (_i, $field) {
      $field.val('');
    });

    $element.addClass('ran'); // Test hook
    $element.data('valid', false);
  }

  function initializeDataElements() {
    const id = Date.now ? Date.now() : new Date().getTime();
    const $container = $(
      '<span data-id="' + id + '" data-for="location-control"></span>'
    );

    $element.data('valid', false);

    $.each(fieldNames, function (_i, fieldName) {
      fields.push(hiddenInputField(fieldName));
    });

    $.each(fields, function (_i, $field) {
      $container.append($field);
    });
    $container.insertAfter($element);
  }

  function cleanOnEdit() {
    $element.on('input', clean);
  }

  function extractBaseName() {
    return $element.attr('name').replace('[location]', '');
  }

  init();
};

export default Control;
