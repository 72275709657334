// eslint-disable-next-line no-restricted-imports
import _ from 'underscore';
import $ from 'legacy/jquery';
import Message from 'legacy/message';

const Tooltip = {
  flyoutHtml:
    '<div id="_gh_tooltip" class="tooltip-flyout">' +
    '<span class="arrow"></span>' +
    '<div id="_gh_tooltip_text"></div>' +
    '</div>',

  flyout: function () {
    return $('#_gh_tooltip');
  },

  setText: function (text) {
    // We are using html() instead of text() because some tooltips use HTML.
    // In theory this is safe because we never pass in user input.
    $('#_gh_tooltip_text').html(text);
  },

  registerClass: function (class_selector) {
    Tooltip.registerParent($(class_selector), null);
  },

  registerParent: function ($parent, childSelector) {
    if (Tooltip.flyout().length === 0) {
      const flyout = $(Tooltip.flyoutHtml);
      $('body').append(flyout);
    }

    if ($.type($parent) === 'string') {
      $parent = $($parent);
    }

    const tooltip = Tooltip.flyout();
    tooltip.on('mouseleave', Tooltip.hideTooltip);
    $parent.on(
      'mouseenter',
      childSelector,
      _.debounce(Tooltip.showTooltip, 100)
    );
    $parent.on(
      'mouseleave',
      childSelector,
      _.debounce(function () {
        if (!tooltip.is(':hover')) {
          Tooltip.hideTooltip();
        }
      }, 100)
    );
  },

  // initialize all tooltips on the page
  init: function () {
    Tooltip.registerClass('.tooltip-icon');
  },

  showTooltip: function () {
    const subject = $(this);
    const key = subject.attr('key') || subject.data('key');
    const description = subject.data('description');
    const imageSrc = subject.data('image-src');
    let message = description || Message.Tooltip[key];

    if (imageSrc) {
      Tooltip.flyout().addClass('image-tooltip');
      message = $('<img/>').attr({
        src: imageSrc,
        width: subject.data('image-width'),
        height: subject.data('image-height'),
      });
    }

    Tooltip.setText(message);

    const tooltip = Tooltip.flyout();
    const tooltipIconOffset = subject.offset();

    tooltip.removeClass('right-side left-side bottom');
    if (
      subject.attr('tooltip-side') === 'right' ||
      subject.data('tooltip-side') === 'right'
    ) {
      // put the tooltip on the right
      tooltip.addClass('right-side');
      tooltip.css({
        top:
          tooltipIconOffset.top -
          tooltip.outerHeight() / 2 +
          subject.outerHeight() / 2,
        left: tooltipIconOffset.left + subject.outerWidth() + 8,
      });
    } else if (
      subject.attr('tooltip-side') === 'left' ||
      subject.data('tooltip-side') === 'left'
    ) {
      // put the tooltip on the right
      tooltip.addClass('left-side');
      tooltip.css({
        top: tooltipIconOffset.top - tooltip.outerHeight() / 2 + 8,
        left: tooltipIconOffset.left - tooltip.outerWidth() - 9,
      });
    } else if (
      subject.attr('tooltip-side') === 'bottom' ||
      subject.data('tooltip-side') === 'bottom'
    ) {
      tooltip.addClass('bottom');
      tooltip.css({
        top: tooltipIconOffset.top + tooltip.outerHeight() + 10,
        left:
          tooltipIconOffset.left -
          tooltip.outerWidth() / 2 +
          subject.outerWidth() / 2,
      });
    } else {
      // by default put the tooltip on top
      tooltip.css({
        top: tooltipIconOffset.top - tooltip.outerHeight() - 17,
        left:
          tooltipIconOffset.left -
          tooltip.outerWidth() / 2 +
          subject.outerWidth() / 2,
      });
    }

    if (subject.data('tooltip-color') === 'grey') {
      tooltip.addClass('grey');
    }

    tooltip.show();
  },

  hideTooltip: function () {
    Tooltip.flyout().hide();
    Tooltip.flyout().prop('class', 'tooltip-flyout');
  },
};

export default Tooltip;
