import _ from 'underscore';
import Ajax from 'legacy/ajax';
import $ from 'legacy/jquery';

const Select2Helper = (function () {
  function lazyLoad($select, optionExtractor, leaveClosedOnLoad) {
    const url = $select.data('url');
    const completeClass = 'lazy-load-complete';
    const params = $select.data('params');

    if ($select.hasClass(completeClass)) {
      return;
    }

    Ajax.get({
      url: url,
      success: loadDataIntoSelect,
      pendingMessage: null,
      data: params,
    });

    $select.addClass(completeClass);

    function loadDataIntoSelect(response) {
      $select.empty();
      $select.append('<option value=""></option>');

      const options = optionExtractor
        ? optionExtractor(response)
        : response.data;

      let selectedOption;
      _.each(options, function (option) {
        appendOption($select, option);
      });

      function appendOption($parent, option) {
        if (option.children) {
          const labelText = option.text || option.name;
          const $group = $("<optgroup label='" + labelText + "'/>");
          $group.val(labelText);

          _.each(option.children, function (child) {
            appendOption($group, child);
          });

          $parent.append($group);
        } else {
          if (option.selected) {
            selectedOption = option;
          }

          const $option = $('<option/>');
          $option.val(option.id);
          $option.text(option.text || option.name);
          $option.attr('selected', option.selected);
          $parent.append($option);
        }
      }

      if (selectedOption) {
        const selectedId = selectedOption.id;
        $select.val(selectedId);
      } else {
        $select.val('');
      }

      $select.trigger('change.select2');

      if (!leaveClosedOnLoad) {
        $select.select2('open');
      }

      setDefaultValue($select);
    }
  }

  function infiniteLoad($select, options) {
    options = options || {};

    $select.select2({
      allowClear: options.allowClear || false,
      formatSearching: options.formatSearching,
      width: '100%',
      minimumInputLength: 0,
      containerCss: { display: 'inline-block' },
      ajax: {
        url: $select.data('url'),
        dataType: 'json',
        quietMillis: 250,
        data: function (term, page) {
          const queryData = {
            q: term,
            page: page,
          };

          Object.assign(queryData, options.queryParams);

          return queryData;
        },
        results: function (data, page) {
          let more;

          if (options.loadPerPage) {
            more = page * options.loadPerPage < data.total_count;
          } else {
            more = data.items.length > 0;
          }

          return { results: data.items, more: more };
        },
      },
    });

    setDefaultValue($select);
  }

  function setDefaultValue($select) {
    if ($select.data('value') && $select.data('value').id) {
      $select.select2('data', $select.data('value'));
      $select.trigger('change');
    }
  }

  const optionExtractors = (function () {
    const defaultToFirstOption = function (response) {
      const options = response.data;
      const firstOption = options[0];
      if (firstOption) {
        firstOption.selected = true;
      }
      return options;
    };

    return {
      defaultToFirstOption: defaultToFirstOption,
    };
  })();

  return {
    lazyLoad: lazyLoad,
    infiniteLoad: infiniteLoad,
    optionExtractors: optionExtractors,
  };
})();

export default Select2Helper;
