import $ from 'legacy/jquery';

const ButtonUtil = {
  enable: function (selector) {
    $(selector).removeClass('disabled-button');
  },

  disable: function (selector) {
    $(selector).addClass('disabled-button');
  },

  isEnabled: function (selector) {
    return !ButtonUtil.isDisabled(selector);
  },

  isDisabled: function (selector) {
    return $(selector).hasClass('disabled-button');
  },

  toggle: function (selector) {
    if (ButtonUtil.isEnabled(selector)) {
      ButtonUtil.disable(selector);
    } else {
      ButtonUtil.enable(selector);
    }
  },
};

export default ButtonUtil;
