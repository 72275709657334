/* eslint-disable no-use-before-define */
const AutoComplete = function (control) {
  function init() {
    setupAutoComplete();
  }

  function setupAutoComplete() {
    control
      .getElement()
      .autocomplete({
        source: source,
        select: selectionMade,
      })
      .autocomplete('instance')._renderItem = renderItem;
  }

  function source(request, response) {
    control.geocode(request.term, response);
  }

  function selectionMade(e, itemWrapper) {
    const result = itemWrapper.item;
    e.preventDefault();
    control.resultSelected(result);
  }

  function renderItem($ul, item) {
    const version = $.ui.version.split('.');
    const $li = $('<li />');

    if (version[1] > 11) {
      const $ele = $('<div />').text(item.displayName);
      $ele.appendTo($li);
    } else {
      $li.text(item.displayName);
    }

    return $li.appendTo($ul);
  }

  init();
};

export default AutoComplete;
