import { withLocalePrefix } from 'shared/utils/translation';
const t = withLocalePrefix('file_uploads.google_drive');

let tokenClient;
let gapiInitialized;
let gisInitialized;

const GoogleDrivePicker = function (args) {
  let $trigger;
  let options;
  let pickerShown = false;

  function init(args) {
    options = args || {};
    $trigger = options.$trigger;

    options.uploadToS3 = options.uploadToS3 || function () {};
    options.uploadCancelled = options.uploadCancelled || function () {};

    $trigger.on('click', call);
  }

  function call() {
    if (!window.gapi || !gapiInitialized || !gisInitialized) {
      options.$messages.text(t('try_again')).show();
      return;
    }

    tokenClient.callback = handleAuthResult;

    if (GoogleDrivePicker.oauthToken === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({ prompt: '' });
    }
  }

  function handleAuthResult(authResult) {
    if (authResult && !authResult.error) {
      GoogleDrivePicker.oauthToken = authResult.access_token;
      createPicker();
    }
  }

  function createPicker() {
    const { google, googlePickerAppId, googlePickerDeveloperKey } = window;
    if (!pickerShown && GoogleDrivePicker.oauthToken) {
      const picker = new google.picker.PickerBuilder()
        .addView(google.picker.ViewId.DOCUMENTS)
        .addView(google.picker.ViewId.PDFS)
        .setOAuthToken(GoogleDrivePicker.oauthToken)
        .setAppId(googlePickerAppId)
        .setDeveloperKey(googlePickerDeveloperKey)
        .setCallback(pickerCallback)
        .enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
        .build();

      picker.setVisible(true);
      options.$messages.text(t('loading_attachment')).show();
      options.$messages.show();

      pickerShown = true;
    }
  }

  function pickerCallback(data) {
    const { google } = window;
    switch (data[google.picker.Response.ACTION]) {
      case google.picker.Action.PICKED:
        pickedFile(data);
        pickerShown = false;
        options.uploadFinished();
        break;
      case google.picker.Action.CANCEL:
        pickerShown = false;
        options.uploadCancelled();
        break;
    }
  }

  function pickedFile(data) {
    const { google } = window;
    const doc = data[google.picker.Response.DOCUMENTS][0];

    downloadFile({
      id: doc[google.picker.Document.ID],
      mimeType: doc[google.picker.Document.MIME_TYPE],
      name: doc[google.picker.Document.NAME],
      type: doc[google.picker.Document.TYPE],
    });
  }

  function dataToArrayBuffer(data) {
    if (!data) {
      return new Uint8Array(0);
    }

    const arrayBuffer = new Uint8Array(data.length);

    for (let i = 0; i < data.length; i++) {
      arrayBuffer[i] = data.charCodeAt(i);
    }

    return arrayBuffer;
  }

  function downloadFile(metadata) {
    const { gapi } = window;

    let request;
    let mimeType = metadata.mimeType;
    let filename = metadata.name;

    if (metadata.type === 'document') {
      request = gapi.client.drive.files.export({
        fileId: metadata.id,
        mimeType: 'application/pdf',
      });

      mimeType = 'application/pdf';
      filename = filename + '.pdf';
    } else {
      request = gapi.client.drive.files.get({
        fileId: metadata.id,
        supportsAllDrives: true,
        alt: 'media',
      });
    }

    request.then(function (response) {
      const arrayBuffer = dataToArrayBuffer(response.body);

      const file = new Blob([arrayBuffer], { type: mimeType });
      file.name = filename;

      options.uploadToS3(file);
    });
  }

  init(args);
};

window.gapiLoaded = function () {
  window.gapi.load('client:picker', initializeGapiClient);
};

window.gisLoaded = function () {
  const { google, googlePickerClientId } = window;

  tokenClient = google.accounts.oauth2.initTokenClient({
    client_id: googlePickerClientId,
    scope: 'https://www.googleapis.com/auth/drive.file',
    callback: '',
  });
  gisInitialized = true;
};

function initializeGapiClient() {
  window.gapi.client
    .load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest')
    .then(function () {
      gapiInitialized = true;
    });
}

export default GoogleDrivePicker;
