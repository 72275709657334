import $ from 'legacy/jquery';
import t from 'shared/utils/translation';

export type FlashMessage = string | Array<string>;

export type FlashManager = {
  remove: (selector?: string | null | undefined) => void;
  fadeOut: () => void;
  fadeOutAfterDelay: () => void;
  fadeIn: () => void;
  setNotice: (message: FlashMessage) => void;
  setError: (message: FlashMessage) => void;
  setDefaultError: () => void;
  setPending: (message: FlashMessage, fade?: boolean) => void;
  setDefaultPending: () => void;
  set: (message: FlashMessage, type: string, fadeOut: boolean) => void;
};

const Flash: FlashManager = {
  remove: function (selector) {
    let el = 'div';

    if (selector) {
      el += selector;
    }

    $('#flash_wrapper').find(el).remove();
  },

  fadeOut: function () {
    $('#flash_wrapper')
      .find('div')
      .fadeOut(250, function (this: any) {
        $(this).remove();
      });
  },

  fadeOutAfterDelay: function () {
    $('#flash_wrapper')
      .find('div')
      .each(function (i: number, div: JQuery.Node) {
        $(div)
          .delay(8000)
          .fadeOut(250, function (this: any) {
            $(this).remove();
          });
      });
  },

  fadeIn: function () {
    $('#flash_wrapper').find('div').fadeIn(500);
  },

  setNotice: function (object) {
    Flash.set(object, 'notice', true);
  },

  setError: function (object) {
    Flash.set(object, 'error', true);
  },

  setDefaultError: function () {
    Flash.set(t('flash.error'), 'error', true);
  },

  setPending: function (object, fade) {
    Flash.set(object, 'pending', !!fade);
  },

  setDefaultPending: function () {
    Flash.set(t('flash.saving'), 'pending', false);
  },

  set: function (object, type, fadeOut) {
    Flash.remove();
    if (object instanceof Array) {
      $.each(object, function (i: number, message: string) {
        addSingleFlash(message);
      });
    } else {
      addSingleFlash(object);
    }

    if (fadeOut) {
      Flash.fadeOutAfterDelay();
    }

    function addSingleFlash(message: string) {
      const flash = $('<div/>');
      flash.addClass('flash-' + type);
      flash.text(message);
      flash.hide();

      $('#flash_wrapper').append(flash);
      flash.fadeIn(250);
    }
  },
};

export default Flash;
