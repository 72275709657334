import { IScope, ITimeoutService } from 'angular';
import $ from 'legacy/jquery';

export default function ghSelectionList($timeout: ITimeoutService) {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    require: 'ngModel',
    link: function (scope: IScope, element: any, attr: any, ngModelCtrl: any) {
      $timeout(function () {
        element.on('click', 'li', selectRow);

        function selectRow(this: any) {
          element.find('.selected').removeClass('selected');
          $(this).addClass('selected');

          const value = $(this).attr('value');
          scope.$apply(function () {
            ngModelCtrl.$setViewValue(value);
          });
        }
      }, 1);
    },
    template: "<ul class='modal-selection-list' ng-transclude></ul>",
  };
}
