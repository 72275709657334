import $ from 'legacy/jquery';
import { Validate } from 'legacy/validation';
import t from 'shared/utils/translation';

const S3DirectUploader = function (args) {
  let $cancelLink;
  let $dropZone;
  let $form;
  let options;
  let $progressBar;
  const skipValidationsFor = {};
  let $trigger;

  function init(args) {
    options = args || {};
    $dropZone = options.$dropZone;
    $form = options.$form;
    $progressBar = options.$progressBar;
    $cancelLink = options.$cancelLink || $progressBar.find('.cancel');
    $trigger = options.$trigger;

    setupForm();
    setupBindings();
  }

  function setupForm() {
    $form.fileupload({
      dataType: 'xml',
      dropZone: $dropZone,
      add: addFile,
      progress: uploadProgress,
      done: uploadSuccess,
      fail: uploadFailed,
      always: uploadFinished,
    });
  }

  function formData(file) {
    const result = $form.serializeArray();
    result.push({ name: 'Content-Type', value: file.type });
    return result;
  }

  function setupBindings() {
    $trigger.on('click', call);
  }

  function uploadFile(file) {
    skipValidationsFor[file.name] = true;
    $form.fileupload('add', { files: [file] });
  }

  function uploadProgress(e, data) {
    const progress = (data.loaded / data.total) * 100.0;
    $progressBar.find('.bar').css('width', progress + '%');
  }

  function uploadSuccess(e, data) {
    const file = data.files[0];
    const url = $(
      data.result.documentElement.getElementsByTagName('Location')
    ).text();

    options.uploadComplete({
      filename: file.name,
      url: url,
    });
  }

  function uploadFailed(e, data) {
    if (data.textStatus === 'abort') {
      options.uploadCancelled();
      return;
    }

    options.uploadFailed(e, data.result);
  }

  function addFile(e, data) {
    const file = data.files[0];
    let xhr;

    // Optionally set a reference to the uploaded file on the caller.
    (options.uploadedFileSetter || function () {})(file);

    setValues();
    options.uploadStarted();

    if (!validateFileIfNecessary(file)) {
      xhr = data.submit();
      xhr.abort();
      return;
    }

    data.formData = formData(file);
    xhr = data.submit();
    bindCancel(xhr);

    $dropZone.removeClass('highlight');
    $dropZone.hide();
    $progressBar.show();
  }

  function setValues() {
    confirmAuthenticityTokenIsSet();
  }

  // This is necessary for tests.
  function confirmAuthenticityTokenIsSet() {
    const $token = $form.find('input[name="authenticity_token"]');

    if ($token.length > 0) {
      return;
    }

    $form.append(
      $('<input/>').attr('name', 'authenticity_token').attr('value', uniqueId())
    );
  }

  function uniqueId() {
    return Math.random().toString(36).substr(2, 16);
  }

  function bindCancel(xhr) {
    $cancelLink.unbind('click');
    $cancelLink.click(function () {
      xhr.abort();
    });
  }

  function validateFileIfNecessary(file) {
    /* If the user pastes an image into the Paste textbox, S3DirectUpload gets called anyway */
    if (!file.name) {
      return false;
    }

    if (options.$fileTypes.length === 0 || skipValidationsFor[file.name]) {
      return true;
    }

    if (!Validate.specificFileFormat(options.$fileTypes)(file.name)) {
      const message = t('add_person.flash_messages.invalid_format', {
        allowed_formats: options.$fileTypes.join(', '),
      });

      alert(message);
      return false;
    }

    return true;
  }

  function uploadFinished(e, data) {
    $progressBar.hide();
  }

  function call(e) {
    e.preventDefault();
    $form.find(':file').click();
  }

  init(args);

  return { uploadFile: uploadFile };
};

export default S3DirectUploader;
