import Ajax from 'legacy/ajax';
import $ from 'legacy/jquery';

/*
 * This function is used to configure the _greenhouse_announcements partial.
 */
export default function configureAnnouncementPartial() {
  const $announcementDiv = $('div#announcement');
  const path = $announcementDiv.data('path');
  const announcementUpdatedAt = $announcementDiv.data('updatedAt');

  $announcementDiv.find('span.hide a').on('click', function () {
    Ajax.post({
      url: path,
      data: { announcement_updated_at: announcementUpdatedAt },
      disableFlash: true,
    });
    $announcementDiv.slideUp();
  });
}
