import Config from 'legacy/config';

const AvailabilityEventFormatter = (function () {
  const DAY_FORMAT = Config.TimeZone.dateFormat(
    'date_without_year_with_day_of_week_2'
  );
  const TIME_FORMAT = Config.TimeZone.timeFormat('time');
  const FULL_FORMAT = DAY_FORMAT + ' ' + TIME_FORMAT;

  function formatAllDay(event) {
    const startTime = event.start;
    // all day events are midnight to midnight, so the end is
    // 12:00am of the *next* day
    const endTime = event.end.subtract(1, 'day');

    const sameDay = startTime.isSame(endTime, 'day');
    const start = startTime.format(DAY_FORMAT);
    const end = endTime.format(DAY_FORMAT);

    return start + (sameDay ? '' : ' &mdash; ' + end);
  }

  function formatTime(event) {
    const sameDay = event.start.isSame(event.end, 'day');
    const start = event.start.format(FULL_FORMAT);
    const end = event.end.format(sameDay ? TIME_FORMAT : FULL_FORMAT);

    return start + ' &mdash; ' + end;
  }

  function format(event) {
    return event.allDay ? formatAllDay(event) : formatTime(event);
  }

  return {
    format: format,
  };
})();

export default AvailabilityEventFormatter;
