import documentReady from 'document-ready';
import $ from 'legacy/jquery';

// Custom event so that we can unbind just the
// outside click event from document, and not
// ALL click events from document
const OUTSIDE_CLICK = 'greenhouse.outsideclick';

// use a singleton pattern to ensure only one instance, global state
let instance = null;

const OutsideClickLock = function () {
  if (instance) {
    return instance;
  }

  this.locked = false;
  this.selector = null;
  this.callback = null;

  instance = this;
};

// Acquires the lock for the given container and
// registers an outside click event.  Callback optional.
OutsideClickLock.prototype.acquire = function (containerSelector, callback) {
  const docLock = this;

  if (docLock.locked) {
    docLock.release();
  }

  docLock.locked = true;
  docLock.selector = containerSelector;
  docLock.callback = callback;

  // This event prevents inside clicks from propagating
  // up through the document.
  $(docLock.selector).on('click', function (e) {
    e.stopPropagation();
  });

  $(document).on(OUTSIDE_CLICK, function (e) {
    // Looks up through the DOM for the selector to make sure
    // the click was not 'inside' the container
    if ($(e.target).closest(docLock.selector).length === 0) {
      docLock.release();
    }
  });
};

// Unbinds events registered by acquire and releases the lock
OutsideClickLock.prototype.release = function () {
  // Standard callback hook (optional)
  if (typeof this.callback === 'function') {
    this.callback.call($(this.selector));
  }

  $(this.selector).unbind('click');
  $(document).unbind(OUTSIDE_CLICK);

  this.locked = false;
};

documentReady(function () {
  // Delegates any click event to trigger a greenhouse.outsideClick
  // this is so we can later unbind just the greenhouse.outsideClick
  // event listeners without unbinding all click listeners from document
  $(document).on('click', function () {
    $(document).trigger(OUTSIDE_CLICK);
  });
});

export default new OutsideClickLock();
