// eslint-disable-next-line no-restricted-imports
import _ from 'underscore';

const StringUtil = {
  URL_REGEX:
    /\b((https?:\/\/|www\.)([A-Za-z0-9\-_=%&@\?\.\/#;=]+)|([a-zA-Z0-9.]+\.[a-zA-Z]{2,3}))\b/gi,

  capitalize: function (str: string) {
    if (typeof str === 'undefined' || str.length === 0) {
      throw new Error(
        'Invalid string ' + str + ' provided to StringUtil.capitalize'
      );
    }

    return str[0].toUpperCase() + str.slice(1, str.length);
  },

  capitalizeEachWord: function (str: string) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },

  stripLinks: function (str: string) {
    return str.replace(/<a\s+[^>]*>/g, '').replace(/<\/a>/g, '');
  },

  stripHtmlTags: function (str: string) {
    return str.replace(/(<([^>]+)>)/gi, '');
  },

  strike: function (str: string) {
    return '<del>' + str + '</del>';
  },

  truncate: function (str: string, maxlen: number) {
    if (str && str.length > maxlen) {
      return str.substr(0, maxlen - 3) + '...';
    }

    return str;
  },

  linkify: function (string: string) {
    if (string === null || string === undefined) {
      return;
    }

    return string.replace(StringUtil.URL_REGEX, function (match) {
      if (match.indexOf('http') === 0) {
        return match.replace(
          StringUtil.URL_REGEX,
          '<a href="$1" target="_blank">$1</a>'
        );
      } else {
        return match.replace(
          StringUtil.URL_REGEX,
          '<a href="http://$1" target="_blank">$1</a>'
        );
      }
    });
  },

  escapeAndLinkify: function (string: string) {
    if (string === null || string === undefined) {
      return;
    }

    const escapedString = _.escape(string);
    return StringUtil.linkify(escapedString);
  },

  htmlEscape: function (string: string) {
    return string
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/&lt;p&gt;/g, '<p>')
      .replace(/&lt;\/p&gt;/g, '</p>');
  },

  toSentence: function (array: Array<string>) {
    if (array.length === 0) {
      return '';
    } else if (array.length === 1) {
      return array[0];
    } else {
      let sentence = array.slice(0, array.length - 1).join(', ');
      if (array.length > 2) {
        sentence += ',';
      }
      sentence += ' and ' + array.slice(-1);
      return sentence;
    }
  },

  pluralize: function (x: unknown, unit: string) {
    return typeof x === 'number' ? x + ' ' + unit + (x === 1 ? '' : 's') : '';
  },

  regexEscape: function (string: string) {
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  },

  toCamelCase: function (string: string) {
    return string.replace(/_([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
  },
};

export default StringUtil;
