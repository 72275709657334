/* eslint-disable no-use-before-define */
import $ from 'legacy/jquery';

const LocateMe = function (control, text) {
  let $locateMe;
  let $msg;
  const ERRORS = {
    1: 'Permission denied',
    2: 'Position unavailable',
    3: 'Request timeout',
  };

  function init() {
    if (navigator && navigator.geolocation) {
      $locateMe = $('<a href="#" />').addClass('locate-me').text(text);

      $locateMe.on('click', locateMe);

      $msg = $('<span />');

      // This is added to preserve the spacing
      $('<label>&nbsp;</label>').insertAfter(control.getElement());

      $locateMe.insertAfter(control.getElement());
      $msg.insertAfter($locateMe);
    }
  }

  function locateMe(e) {
    e.preventDefault();

    const timeoutVal = 1000 * 60; // Wait for one minute

    navigator.geolocation.getCurrentPosition(displayPosition, displayError, {
      enableHighAccuracy: true,
      timeout: timeoutVal,
    });
  }

  function displayPosition(position) {
    control.reverseGeocode(
      position.coords.latitude,
      position.coords.longitude,
      callback
    );
  }

  function displayError(error) {
    $msg.addClass('error').text(ERRORS[error.code]);
    setTimeout(function () {
      $msg.removeClass('error').text('');
    }, 6000);
  }

  function callback(data) {
    control.resultSelected(data[0]);
  }

  init();
};

export default LocateMe;
