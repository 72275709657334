import $ from 'legacy/jquery';
import { LegacyValidator, Validate } from 'legacy/validation';

if (!$.fn.validateOn) {
  $.fn.validateOn = function (
    eventType: keyof HTMLElementEventMap,
    validateFunc: (event: Event) => LegacyValidator | boolean[],
    afterSuccessfulValidation?: (event: Event) => unknown,
    options?: { scroll?: boolean }
  ) {
    $(this).on(eventType, function (this: JQuery, event: Event) {
      // NOTE: by calling the validation function this way, we're ensuring "this" inside the function
      // is the same as "this" outside the function, i.e. it references the form being validated.
      const results = validateFunc.call(this, event);

      // supports a return value of either a Validator, or an array of validations
      const valid =
        typeof (results as LegacyValidator).isValid === 'function'
          ? (results as LegacyValidator).isValid()
          : Validate.isValid(results as boolean[]);

      if (!valid) {
        if (options === undefined || options.scroll !== false) {
          Validate.scrollToFirstError();
        }
        return false;
      }

      // if defined, call the afterValidation callback
      if (afterSuccessfulValidation) {
        return afterSuccessfulValidation.call(this, event);
      }
    });
  };
}

if (!$.fn.offOn) {
  $.fn.offOn = function (
    event: keyof HTMLElementEventMap,
    listener: (e: Event) => unknown
  ) {
    this.off(event).on(event, listener);
  };
}
