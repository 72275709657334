import Ajax from 'legacy/ajax';
import Flash from 'legacy/flash';
import $ from 'legacy/jquery';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'legacy/routes'. 'app/webpack/javascripts/legacy/routes.js' implicitly has an 'any' type.
import Routes from 'legacy/routes';

const ExportExcel = (function () {
  const refreshIntervalInSeconds = 2;
  const maxMinutesToRefresh = 30;
  const maxRefreshAttempts =
    (maxMinutesToRefresh * 60) / refreshIntervalInSeconds;
  let refreshAttempt = 0;
  // @ts-expect-error - TS7034 - Variable 'exportCallbackId' implicitly has type 'any' in some locations where its type cannot be determined.
  let exportCallbackId;

  function registerEvents() {
    $('.export-to-excel-label').on('click', startExport);
  }

  /**
   * @param url Optional; if not passed, must be specified as the href attribute on the Export button
   */
  function startExport(_event: any, url: string, onComplete?: () => void) {
    // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
    const $wrapper = $(this).closest('.export-report-wrapper');
    const count = $('#count_placeholder, #results_count').text();

    if (!$wrapper.hasClass('exporting')) {
      $wrapper.addClass('exporting');

      Ajax.get<{ delayed: boolean; file_url: string }>({
        // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
        url: url || $(this).attr('href'),
        data: { result_count: count },
        dataType: 'json',
        success: function (response) {
          refreshAttempt = 0;
          if (response?.delayed) {
            checkStatusWithDelay(response, $wrapper, onComplete);
          } else if (response?.file_url) {
            ExportExcel.downloadFile(response.file_url, $wrapper);
            onComplete && onComplete();
          }
        },
        error: function () {
          $wrapper.removeClass('exporting');
          onComplete && onComplete();
        },
      });
    }

    return false;
  }

  function checkStatusWithDelay(
    response: any,
    $wrapper: any,
    onComplete: (() => void) | undefined
  ) {
    exportCallbackId = window.setTimeout(function () {
      checkStatus(response.background_task_id, $wrapper, onComplete);
    }, refreshIntervalInSeconds * 1000);
  }

  function checkStatus(
    backgroundTaskId: any,
    $wrapper: any,
    onComplete: (() => void) | undefined
  ) {
    refreshAttempt++;
    if (refreshAttempt <= maxRefreshAttempts) {
      Ajax.post<{ export_status: string }>({
        url: Routes.export_status_path,
        data: { id: backgroundTaskId },
        disableFlash: true,
        error: function () {
          exportError($wrapper);
        },
        success: function (response) {
          if ('complete' === response?.export_status) {
            // @ts-expect-error - TS2345 - Not typed for custom response.status handlers
            ExportExcel.downloadFile(response.file_url, $wrapper);
            onComplete && onComplete();
          } else if ('failed' === response?.export_status) {
            exportError($wrapper);
            onComplete && onComplete();
          } else {
            checkStatusWithDelay(response, $wrapper, onComplete);
          }
        },
      });
    } else {
      exportError($wrapper);
      onComplete && onComplete();
    }
  }

  function getExportCallbackId() {
    // @ts-expect-error - TS7005 - Variable 'exportCallbackId' implicitly has an 'any' type.
    return exportCallbackId;
  }

  function exportError($wrapper: any) {
    Flash.setError('Failed to generate report');
    $wrapper.removeClass('exporting');
  }

  function downloadFile(url: string, $wrapper: any) {
    $('<iframe/>')
      .attr({ style: 'display: none', src: url })
      .appendTo(document.body);
    $wrapper.removeClass('exporting');
  }

  return {
    init: registerEvents,
    startExport: startExport,
    downloadFile: downloadFile,
    getExportCallbackId: getExportCallbackId,
  };
})();

export default ExportExcel;
