/* eslint-disable no-use-before-define */
import $ from 'legacy/jquery';
import MapboxResult from './result';

const MapboxRequest = function (options) {
  const baseUrl = 'https://api.mapbox.com/geocoding/v5/';
  const autoCompleteEndpoint = 'mapbox.places';
  const permanentEndpoint = 'mapbox.places-permanent';
  options = options || {};
  let limit = options.limit;
  const language = options.language;
  const locationType = options.locationType || 'city';
  const country = options.country;
  const typeConversion = {
    address: 'address,place,locality',
    city: 'place,locality',
    state: 'region,place,locality',
    country: 'country',
  };
  const exactConversion = {
    city: 'place',
  };

  if (limit === undefined) {
    limit = true;
  }

  this.autoComplete = function (term, callback) {
    call(autoCompleteEndpoint, term, callback, false);
  };

  this.permanent = function (term, callback) {
    call(permanentEndpoint, term, callback, true);
  };

  function resultsFrom(data) {
    return $.map(data.features, function (datum) {
      return new MapboxResult(datum);
    });
  }

  function call(endpoint, term, callback, permanent) {
    $.ajax({
      url: url(endpoint, term),
      data: params(permanent),
      success: function (data) {
        callback(resultsFrom(data));
      },
      error: function () {
        callback([]);
      },
    });
  }

  function url(endpoint, term) {
    return baseUrl + endpoint + '/' + term + '.json';
  }

  function params(permanent) {
    const paramsHash = {
      access_token: window.LocationControl.Providers.Mapbox.apiKey,
    };

    if (language) {
      paramsHash.language = language;
    }

    if (country) {
      paramsHash.country = country;
    }

    if (permanent) {
      if (limit) {
        paramsHash.limit = 1;
      }
      paramsHash.types = exactConversion[locationType] || locationType;
    } else {
      paramsHash.autocomplete = true;
      paramsHash.types = typeConversion[locationType];
      if (limit) {
        paramsHash.limit = 10;
      }
    }

    return paramsHash;
  }
};

export default MapboxRequest;
