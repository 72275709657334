import { IHttpService } from 'angular';

export default function StorableRequest(
  $http: IHttpService,
  localStorageService: any
) {
  /**
   * Abstraction to read/write from local storage values more easily
   *
   * @param {string} globalLocalStorageKey - the key used to save in local storage
   * */
  class LocalStorageCache {
    globalLocalStorageKey: string;

    constructor(globalLocalStorageKey: string) {
      this.globalLocalStorageKey = globalLocalStorageKey;
    }

    getStorageObject() {
      return localStorageService.get(this.globalLocalStorageKey) || {};
    }

    get = function (this: any, key: string) {
      const storage = this.getStorageObject();
      return storage[key];
    };

    put = function (this: any, key: string, value: any) {
      const storage = this.getStorageObject();
      storage[key] = value;

      return localStorageService.set(this.globalLocalStorageKey, storage);
    };

    remove = function (this: any, key: string) {
      delete this.getStorageObject()[key];
    };
  }

  // ensure the key is unique to prevent key collisions between multiple tabs but ensure
  // that it's saved by URL to ensure that reloading the same url would hit the same local
  // storage entry
  const localStorageKey =
    'ajax-offline-store-' + window.btoa(window.location.pathname);
  const cache = new LocalStorageCache(localStorageKey);

  /**
   * The actual type users of this file work with
   *
   * @param {LocalStorageCache} localStorageCache - used to store the actual value when store()
   *                                    is called
   * @param {string} key - the key to store the request under
   * @param {Object} requestConfig - the config to feed the $http(..) method for calls.
   *                                 This would contain everything to make a network call
   * */
  function StorableRequest(
    this: any,
    localStorageCache: LocalStorageCache,
    key: string,
    requestConfig: any
  ) {
    this.store = function () {
      localStorageCache.put(key, requestConfig);
      return this;
    };

    this.send = function () {
      localStorageCache.remove(key);
      return $http(requestConfig);
    };
  }

  return {
    /**
     * Retrieves the stored ajax request.
     *
     * @param {String} key - the key under which the request was saved
     * @return {StorableRequest, null}
     * */
    get: function get(key: string) {
      const config = cache.get(key);
      if (!config) {
        return null;
      }

      // @ts-expect-error - TS7009
      return new StorableRequest(cache, key, config);
    },

    /**
     * @param {String} key - the key used to store the config object
     * @param {Object} config - Request config object for $http(config)
     */
    create: function create(key: string, config: any) {
      // @ts-expect-error - TS7009
      return new StorableRequest(cache, key, config);
    },
  };
}
