/**
 * Form validation functions
 */

declare global {
  interface RegExp {
    test(x: string | number | undefined | null): boolean;
    exec(string: string | number | undefined | null): RegExpExecArray | null;
  }
}

import moment from 'moment';
import Config from 'legacy/config';
import Flash from 'legacy/flash';
import Handlebars from 'legacy/handlebars';
import $ from 'legacy/jquery';
import t from 'shared/utils/translation';

type ValidateFunction =
  | ((...args: Array<string | undefined>) => boolean)
  | ((
      ...args: Array<string | undefined>
    ) => (...nestedArgs: Array<string | undefined>) => boolean);

interface LegacyValidate {
  ERROR_TEMPLATE: (args: { msg: string }) => string;
  alphanumeric(val?: string): boolean;
  wordCharacter(val?: string): boolean;
  // eslint-disable-next-line no-use-before-define
  checked(this: LegacyValidator, val?: string): boolean;
  numeric(val?: string): boolean;
  decimal(val?: string): boolean;
  decimalWithMaxPrecision(precision: number): (val?: string) => boolean;
  notEmpty(val?: string): boolean;
  notBlank(val?: string): boolean;
  blank(val?: string): boolean;
  email(val?: string): boolean;
  multipleEmails(val?: string): boolean;
  emailOrMyEmailAddressToken(val?: string): boolean;
  emailDomain(val?: string): boolean;
  notEmailAddress(val?: string): boolean;
  twitterHandle(val?: string): boolean;
  fileFormat(val?: string): boolean;
  specificFileFormat(extension: string | string[]): (val: string) => boolean;
  blocklistedExtension(val?: string): boolean;
  imageFormat(val?: string): boolean;
  url(val?: string): boolean;
  urlWithProtocol(val?: string): boolean;
  httpsUrl(val?: string): boolean;
  httpsUrlPortAllowed(val?: string): boolean;
  urlOrTwitterHandle(val?: string): boolean;
  minLength(length: number): (val?: string) => boolean;
  maxLength(length: number): (val?: string) => boolean;
  maxLengthUrlEncoded(length: number): (val?: string) => boolean;
  maxLengthTwitterMessage(length: number): (val?: string) => boolean;
  arrayMinLength(length: number): (val?: string) => boolean;
  arrayMaxLength(length: number): (val?: string) => boolean;
  maxNumberOfOptions(max: number): (val?: string) => boolean;
  maxOptionCharLength(max: number): (val?: string) => boolean;
  matches<T>(actual: T): (expected?: T) => boolean;
  matchesCaseInsensitive(actual: string): (expected?: string) => boolean;
  matchesRegex(re: RegExp): (val?: string) => boolean;
  doesNotMatchRegex(re: RegExp): (val?: string) => boolean;
  greaterThan<T>(test: T): (actual?: T) => boolean;
  lessThan<T>(test: T): (actual?: T) => boolean;
  inRange<T>(left: T, right: T): (n?: T | string) => boolean;
  notPresentIn(
    a: Array<string>,
    caseInsensitive?: boolean
  ): (val?: string) => boolean;
  atLeastOneChecked(setId?: string): boolean;
  // eslint-disable-next-line no-use-before-define
  atLeastOneCheckboxChecked(this: LegacyValidator): boolean;
  maxCheckboxesChecked(
    max: number,
    parentFilter?: string
    // eslint-disable-next-line no-use-before-define
  ): (this: LegacyValidator) => boolean;
  matchAllAndReturnFirstGroup(re: RegExp, stringToMatch?: string): string[];
  findTokenCandidates(body?: string): string[];
  emailTemplateWithTokens(
    validTokens: string[],
    requiredTokens: string[]
  ): (body?: string) => boolean;
  emailTemplateToken(val: string, validTokens: string[]): boolean;
  passes<T>(result: T): () => T;
  dateFormat(value?: string): boolean;
  field(
    selector: string,
    validateFunction: ValidateFunction,
    message: string,
    allowEmpty?: boolean,
    trim?: boolean,
    setErrorToOtherSelector?: string | JQuery
  ): boolean;
  fieldWithTrim(
    selector: string,
    validateFunction: ValidateFunction,
    message: string,
    allowEmpty?: boolean
  ): boolean;
  setError(selector: string | JQuery, message: string): void;
  clearError(selector: string | JQuery): void;
  isValid(validations: boolean[]): boolean;
  fail(): false;
  scrollToFirstError(): undefined | void;
}

export interface LegacyValidator
  extends Omit<
    LegacyValidate,
    | 'ERROR_TEMPLATE'
    | 'field'
    | 'setError'
    | 'clearError'
    | 'isValid'
    | 'scrollToFirstError'
  > {
  // eslint-disable-next-line @typescript-eslint/no-misused-new
  new (): LegacyValidator;
  selector: string | JQuery;
  validations: boolean[];
  message?: string | null;
  resetFlags(): void;
  validate(selector: string): this;
  allowEmpty(): this;
  whenPresent(): this;
  whenEnabled(): this;
  withMessage(message: string): this;
  doValidate<T>(validateFunction: ValidateFunction, args?: Array<T>): boolean;
  isValid(): boolean;
}

function someChecked($elements: JQuery) {
  for (let i = 0; i < $elements.length; i++) {
    if ($($elements[i]).is(':checked')) {
      return true;
    }
  }

  return false;
}

function uniq(items: unknown[]) {
  return items.reduce<unknown[]>(function (collection, item) {
    if (collection.indexOf(item) === -1) {
      return collection.concat([item]);
    }

    return collection;
  }, []);
}

const Validate: LegacyValidate = {
  ERROR_TEMPLATE: Handlebars.compile(
    '<div class="field-error-msg">{{msg}}</div>'
  ),

  alphanumeric: function (val) {
    return /^[a-zA-Z0-9]+$/.test(val);
  },

  wordCharacter: function (val) {
    return /^\w+$/.test(val);
  },

  checked: function (this) {
    return $(this.selector).is(':checked');
  },

  numeric: function (val) {
    return /^[0-9]+$/.test(val);
  },

  decimal: function (val) {
    return /^-?(([0-9]+)|(\.[0-9]+)|([0-9]+\.[0-9]+))$/.test(val);
  },

  decimalWithMaxPrecision: function (numDigits) {
    return function (val) {
      return (
        Validate.decimal(val) &&
        // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
        val.toString().replace(/[-.]/g, '').length <= numDigits
      );
    };
  },

  notEmpty: function (val) {
    return val !== null && $.trim(val).length > 0;
  },

  notBlank: function (val) {
    return $.trim(val).length > 0;
  },

  blank: function (val) {
    return $.trim(val).length === 0 || val === 'None';
  },

  // Allows either a raw email address, or one with a display name, such as: "Bob Johnson" <bob@johnson.com>
  email: function (val) {
    const emailRegex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})+$/i;

    if (emailRegex.test(val)) {
      return true;
    } else {
      const displayRegex = /^.+<(.+)>$/;
      const match = displayRegex.exec(val);

      return !!(match && emailRegex.test(match[1]));
    }
  },

  multipleEmails: function (val) {
    // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
    const emails = val.split(/\s*,\s*/);
    return emails.every(Validate.email);
  },

  emailOrMyEmailAddressToken: function (val) {
    return Validate.email(val) || val === '{{MY_EMAIL_ADDRESS}}';
  },

  emailDomain: function (val) {
    return (
      // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
      val.indexOf('.') > 0 &&
      // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
      val.indexOf(' ') < 0 &&
      Validate.notEmailAddress(val)
    );
  },

  notEmailAddress: function (val) {
    // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
    return val.indexOf('@') < 0;
  },

  twitterHandle: function (val) {
    return /@([A-Za-z0-9_]+)/.test(val);
  },

  fileFormat: function (val) {
    // eslint-disable-next-line max-len
    // this needs to match the ACCEPTED_FORMATS in https://github.com/grnhse/greenhouse/blob/main/app/concepts/sourcing/bulk_imports/resume_attacher.rb
    return /\.(pdf|doc|docx|txt|rtf)$/i.test(val);
  },

  specificFileFormat: function (extension) {
    return function (val) {
      const extensions = extension instanceof Array ? extension : [extension];
      return new RegExp('.(' + extensions.join('|') + ')', 'i').test(val);
    };
  },

  blocklistedExtension: function (val) {
    const blocklisted = window?.Email?.blocklistedExtensions || [];
    return new RegExp('.(' + blocklisted.join('|') + ')$', 'i').test(val);
  },

  imageFormat: function (val) {
    return /\.(png|jpg|jpeg|gif)$/i.test(val);
  },

  url: function (val) {
    return /^(http:\/\/|https:\/\/)?[0-9a-z\-]+\.[0-9a-z\.\-]+(\/.*)?$/i.test(
      val
    );
  },

  urlWithProtocol: function (val) {
    return /https?:\/\/[0-9a-z\-]+\.[0-9a-z\.\-]+(\/.*)?$/i.test(val);
  },

  httpsUrl: function (val) {
    return /^https:\/\/[0-9a-z\-]+\.[0-9a-z\.\-]+(\/.*)?$/i.test(val);
  },

  httpsUrlPortAllowed: function (val) {
    return /^https:\/\/[0-9a-z\-]+\.[0-9a-z\.\-]+(:[0-9]+)?(\/.*)?$/i.test(val);
  },

  urlOrTwitterHandle: function (val) {
    return Validate.url(val) || Validate.twitterHandle(val);
  },

  minLength: function (minLength) {
    return function (val) {
      return typeof val === 'string' && val.length >= minLength;
    };
  },

  maxLength: function (maxLength) {
    return function (val) {
      return typeof val === 'string' && val.length <= maxLength;
    };
  },

  maxLengthUrlEncoded: function (maxLength) {
    return function (val) {
      return (
        typeof val === 'string' && encodeURIComponent(val).length <= maxLength
      );
    };
  },

  maxLengthTwitterMessage: function (maxLength) {
    return function (val) {
      return typeof val === 'string' &&
        // TODO: currentTwitterMessageLength comes from app/webpack/javascripts/dashboard/social_media_url_shim.js
        window.currentTwitterMessageLength
        ? window.currentTwitterMessageLength(val) <= maxLength
        : false;
    };
  },

  arrayMinLength: function (minLength) {
    return function (val) {
      // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
      return val !== null && val.length >= minLength;
    };
  },

  arrayMaxLength: function (maxLength) {
    return function (val) {
      // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
      return val === null || val.length <= maxLength;
    };
  },

  maxNumberOfOptions: function (maxOptions) {
    return function (val) {
      // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
      const optionsValArray = val.split(/\r|\n/);
      const arrayWithoutEmptyValues = uniq(optionsValArray).filter(Boolean);

      return arrayWithoutEmptyValues.length <= maxOptions;
    };
  },

  maxOptionCharLength: function (maxCharLength) {
    return function (val) {
      // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
      const optionsValArray = val.split(/\r|\n/);
      const maxCharLengthExceeded = optionsValArray.some(function (el) {
        return el.length > maxCharLength;
      });

      return !maxCharLengthExceeded;
    };
  },

  matches: function (match) {
    return function (val) {
      return val === match;
    };
  },

  matchesCaseInsensitive: function (match) {
    return function (val) {
      // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
      return val.toLowerCase() === match.toLowerCase();
    };
  },

  matchesRegex: function (regex) {
    return function (val) {
      // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
      return val.match(regex) !== null;
    };
  },

  doesNotMatchRegex: function (regex) {
    return function (val) {
      // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
      return val.match(regex) === null;
    };
  },

  greaterThan: function (other) {
    return function (val) {
      // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
      return parseInt(val, 10) > parseInt(other, 10);
    };
  },

  lessThan: function (other) {
    return function (val) {
      // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
      return parseInt(val, 10) < parseInt(other, 10);
    };
  },

  inRange: function (left, right) {
    return function (val) {
      return (
        // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
        parseInt(val, 10) >= parseInt(left, 10) &&
        // @ts-expect-error - TS2532 possibly undefined - val is always defined through Validator
        parseInt(val, 10) <= parseInt(right, 10)
      );
    };
  },

  notPresentIn: function (array, caseInsensitive) {
    return function (val) {
      if (caseInsensitive) {
        if (array) {
          array = $.map(array, function (item: string) {
            return item.toLowerCase();
          });
        }

        if (val) {
          val = val.toLowerCase();
        }
      }

      return $.inArray(val, array) === -1;
    };
  },

  atLeastOneChecked: function (setId) {
    return someChecked($('input[required][set=' + setId + ']'));
  },

  atLeastOneCheckboxChecked: function () {
    return someChecked($(this.selector).find('input[type="checkbox"]'));
  },

  maxCheckboxesChecked: function (max, parentFilter) {
    return function () {
      const $selection = $(this.selector)
        .find(parentFilter + ' input[type="checkbox"]')
        .filter(':checked');

      return $selection.length <= max;
    };
  },

  matchAllAndReturnFirstGroup: function (regex, stringToMatch) {
    const matchingTokens = [];

    let match;
    while ((match = regex.exec(stringToMatch)) !== null) {
      matchingTokens.push(match[1]);
    }

    return matchingTokens;
  },

  // Finds all possible tokens within the given body string.
  // These tokens are not guaranteed to be valid; valid tokens must start with two open braces,
  // contain one or more word chars, and end with two close braces - e.g. "{{TOKEN}}", "{{TOKEN_VAL}}".
  // But all token candidates will either start with an open brace - "{" - AND end with a close brace - "}" -
  // with some combination of word characters or braces in between - e.g. "{{{TOKEN}", "{TOKEN}", "{{{TOKEN}}}"
  findTokenCandidates: function (body) {
    // Regex matches the optional start of the line or a non-open brace char
    const optionalPreviousCharRegex = /(?:^|[^{])?/.source;
    // Regex matches the optional end of the line or a non-close brace char
    const optionalNextCharRegex = /(?:$|[^}])?/.source;
    // Regex matches a token starting with at least one open brace, at least one close brace,
    // and some combo of word chars and braces in between - e.g. "{TOKEN}", "{{TOKEN_CHAR}}}", "{}{}{}{}"
    const openedAndClosedTokenRegex = /({[{}\w]+})/.source;

    // Regex matches a token starting and ending with braces,
    // with optional previous and next surrounding non-brace chars - e.g. "{{TOKEN}}" or " {TOKEN}," or "\{{{TOKEN}} "
    // The previous and next chars are in non-captured groups and the token is in the only captured group
    const tokenAndSurroundingCharsRegex = new RegExp(
      optionalPreviousCharRegex +
        openedAndClosedTokenRegex +
        optionalNextCharRegex,
      'g'
    );

    // Return matching tokens within the capture group, and strip surrounding chars
    return Validate.matchAllAndReturnFirstGroup(
      tokenAndSurroundingCharsRegex,
      body
    );
  },

  emailTemplateWithTokens: function (validTokens, requiredTokens) {
    return function (this: LegacyValidator, body) {
      // Find any correctly-formatted or malformed tokens in the email text
      const malformedTokens: string[] = [];
      const invalidTokens: string[] = [];
      const usedTokens: string[] = [];
      let valid = true;

      const tokenCandidates = Validate.findTokenCandidates(body);
      $.each(tokenCandidates, function (_i: number, token: string) {
        // Identify tokens that are malformed,
        // i.e. starting and ending with more than two curly braces
        if (!token.match(/^{{\w+}}$/)) {
          malformedTokens.push(token);
        }

        // Identify tokens that are not allowed for the given template
        if (!Validate.emailTemplateToken(token, validTokens)) {
          invalidTokens.push(token);
        }

        if (
          requiredTokens &&
          Validate.emailTemplateToken(token, requiredTokens)
        ) {
          usedTokens.push(token);
        }
      });

      if (malformedTokens.length !== 0) {
        this.message = t('messages.validation.malformed_placeholders', {
          tokens: malformedTokens.join(', '),
        });
        valid = false;
      } else if (invalidTokens.length !== 0) {
        this.message = t('messages.validation.invalid_placeholders', {
          tokens: invalidTokens.join(', '),
        });
        valid = false;
      } else if (
        requiredTokens &&
        uniq(usedTokens).length !== requiredTokens.length
      ) {
        const unusedRequiredTokens = requiredTokens.filter(function (token) {
          return usedTokens.indexOf(token) === -1;
        });

        this.message = t('messages.validation.required_placeholders', {
          tokens: unusedRequiredTokens.join(', '),
        });
        valid = false;
      }

      return valid;
    };
  },

  emailTemplateToken: function (val, validTokens) {
    return validTokens.indexOf(val) > -1;
  },

  passes: function (result) {
    return function () {
      return result;
    };
  },

  dateFormat: function (value) {
    if (/[0-9]{1,4}\/[0-9]{1,4}\/[0-9]{1,4}/.test(value)) {
      if (
        typeof moment === 'function' &&
        !moment(value, Config.TimeZone.dateFormat('date'), true).isValid()
      ) {
        return false;
      }

      return true;
    } else {
      return false;
    }
  },

  // eslint-disable-next-line max-params
  field: function (
    selector,
    validateFunction,
    message,
    allowEmpty,
    trim,
    setErrorToOtherSelector
  ) {
    // @ts-expect-error - TS2339: Property selector does not exist on type LegacyValidate
    this.selector = selector;
    this.clearError(selector);
    if (setErrorToOtherSelector) {
      this.clearError(setErrorToOtherSelector);
    }

    if (trim) {
      const trimmedValue = $.trim($(selector).val());
      $(selector).val(trimmedValue);
    }

    const value = $(selector).val();
    const condition =
      (allowEmpty === true && value === '') ||
      // @ts-expect-error TS2684: The this context of type ValidateFunction is not assignable to method's this of type
      validateFunction.call(this, value);

    if (!condition) {
      this.setError(setErrorToOtherSelector || selector, message);
      // LOG THE FAILURE SOMEWHERE
    }

    return condition;
  },

  fieldWithTrim: function (selector, validateFunction, message, allowEmpty) {
    return Validate.field(
      selector,
      validateFunction,
      message,
      allowEmpty,
      true
    );
  },

  setError: function (selector, message) {
    $(selector).addClass('field-error');

    if ($(selector).hasClass('select2')) {
      $($(selector).select2('container')).addClass('field-error');
    }

    if (message) {
      $(selector)
        .parent()
        // eslint-disable-next-line new-cap
        .append(this.ERROR_TEMPLATE({ msg: message }));
    }
  },

  clearError: function (selector) {
    $(selector).removeClass('field-error');
    $(selector).siblings('.field-error-msg').remove();

    if ($(selector).hasClass('select2')) {
      $($(selector).select2('container')).removeClass('field-error');
    }
  },

  isValid: function (validations) {
    if (Array.isArray(validations)) {
      return validations.every(Boolean);
    } else {
      return !!validations;
    }
  },

  fail: function () {
    return false;
  },

  scrollToFirstError: function () {
    const $visibleModals = $('.modal-body:visible');
    let $error;
    let scrollPosition;

    if ($visibleModals.length > 0) {
      $error = $visibleModals.find('.field-error:first');
    } else {
      $error = $('.field-error:first');
    }
    // When Chosen and Select2 fields are present, errors are added to hidden fields (display: none)
    // which are not rendered and do not have an offset or position
    // Act on the visible element instead
    if ($error.is('.chzn-select, .select2-offscreen, .select2-tags')) {
      $error = $error.siblings('.chzn-container, .select2-container');
    } else if ($error.is('.editor')) {
      $error = $error.siblings('.tox-tinymce');
    }

    const failedValidation = $('.field-error')
      .toArray()
      .map(
        (i: {
          id: string | undefined;
          name: string | undefined;
          outerHTML: string;
        }) => i.id || i.name || i.outerHTML
      );

    // @ts-expect-error - TS2339: Property DD_LOGS does not exist on type Window & typeof globalThis
    window.DD_LOGS?.logger?.log('Form failed validation', {
      failed_fields: failedValidation,
    });

    if ($error.length === 0) {
      return;
    }

    if ($visibleModals.length > 0) {
      if ($visibleModals.hasClass('scrollable')) {
        scrollPosition =
          $error.offset().top -
          $visibleModals.offset().top +
          $visibleModals.scrollTop() -
          90;
        $visibleModals.animate({ scrollTop: scrollPosition }, 'fast');
      }
    } else {
      scrollPosition = $error.offset().top - 100;
      $('html, body').animate({ scrollTop: scrollPosition }, 'fast');
    }
  },
};

/**
 * Provides a fluid interface for describing form validation.
 */
const Validator: LegacyValidator = (function () {
  function Construct(this: LegacyValidator) {
    this.validations = [];
    this.resetFlags();
  }

  Construct.prototype.resetFlags = function () {
    this.allowMissingElement = false;
    this.ignoreDisabled = false;
    this.allowEmptyVal = false;
  };

  /** Set the jQuery-esque 'selector' used to discover the element to be validated */
  Construct.prototype.validate = function (selector: string | JQuery) {
    this.selector = selector;
    return this;
  };

  /** Validation will pass if the element's value is the empty string */
  Construct.prototype.allowEmpty = function () {
    this.allowEmptyVal = true;
    return this;
  };

  /** Validation will pass if no element is found on the page that matches the selector */
  Construct.prototype.whenPresent = function () {
    this.allowMissingElement = true;
    return this;
  };

  /** Validation will pass if this element is not enabled */
  Construct.prototype.whenEnabled = function () {
    this.ignoreDisabled = true;
    return this;
  };

  /** Set the message that will be displayed if form validation fails */
  Construct.prototype.withMessage = function (message: string) {
    this.message = message;
    return this;
  };

  Construct.prototype.doValidate = function (
    validateFunction: ValidateFunction,
    args: Array<string | undefined>
  ) {
    Validate.clearError(this.selector);

    const $elements = $(this.selector);
    const value = $elements.val();
    const actualValidateFunction =
      args.length === 0 ? validateFunction : validateFunction(...args);
    let condition = true;

    if ($elements.length === 0) {
      if (!this.allowMissingElement) {
        Flash.setError(t('messages.validation.form_error'));
        condition = false;
      } else {
        condition = true;
      }
    } else {
      if (this.ignoreDisabled && $elements.attr('disabled') === 'disabled') {
        condition = true;
      } else {
        condition =
          (this.allowEmptyVal && $.trim(value).length === 0) ||
          // @ts-expect-error - TS2339: Property call does not exist on type boolean | ValidateFunction
          actualValidateFunction.call(this, value);
      }

      if (!condition) {
        Validate.setError(this.selector, this.message);
      }
    }

    this.validations.push(condition);

    this.message = null;
    this.resetFlags();

    return condition;
  };

  const blocklist = [
    'ERROR_TEMPLATE',
    'field',
    'setError',
    'clearError',
    'isValid',
    'scrollToFirstError',
  ];

  // eslint-disable-next-line guard-for-in
  for (const name in Validate) {
    // eslint-disable-next-line max-len
    // @ts-expect-error - TS7053: Element implicitly has an any type because expression of type string can't be used to index type LegacyValidate
    const func = Validate[name];

    if (typeof func === 'function' && !blocklist.includes(name)) {
      // eslint-disable-next-line no-shadow
      (function (name, func) {
        Construct.prototype[name] = function () {
          // eslint-disable-next-line prefer-rest-params
          return this.doValidate(func, arguments);
        };
      })(name, func);
    }
  }

  Construct.prototype.isValid = function () {
    if (Array.isArray(this.validations)) {
      return this.validations.every(Boolean);
    } else {
      return !!this.validations;
    }
  };

  return Construct;
})() as unknown as LegacyValidator;

export { Validate, Validator };
