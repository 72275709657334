import { IAttributes, IScope } from 'angular';

interface InputWithButtonScope extends IScope {
  value: any;
  inputClass: string;
  buttonClass: string;
  buttonText: string;
  method: (param: { newValue: string }) => void;
}

interface Attributes extends IAttributes {
  value: any;
  inputClass: string;
  buttonClass: string;
  buttonText: string;
  autofocusAfterMethod: string;
}

// @ts-expect-error - TS2339
const contentSecurityPolicy = window.contentSecurityPolicy;

export default function ghInputWithButton() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      method: '&',
    },
    link: function (
      scope: InputWithButtonScope,
      element: any,
      attrs: Attributes
    ) {
      scope.value = attrs.value;
      scope.inputClass = attrs.inputClass;
      scope.buttonClass = attrs.buttonClass;
      scope.buttonText = attrs.buttonText;

      const input = element.find('input');

      input.on('keypress', function (event: any) {
        if (event.which === 13) {
          callMethod();
        }
      });

      element
        .find('a')
        .on('click', callMethod)
        .on('click', contentSecurityPolicy.hrefPreventDefault);

      function callMethod() {
        scope.method({ newValue: input.val() });
        scope.$apply();

        //used to tell whether it should refocus on the input after the method action occurs.
        if (attrs.autofocusAfterMethod === 'true') {
          input.focus();
        }
      }
    },
    template:
      '<div>' +
      "<input type='text' value='{{value}}' class='change-attribute-name'>" +
      "<a href='#' class='{{ buttonClass }}'>{{ buttonText }}</a>" +
      '</div>',
  };
}
