/* eslint-disable no-use-before-define */

const MapboxResult = function (data) {
  setParts([data], this);
  if (data.context) {
    setParts(data.context, this);
  }

  function isType(part, partType) {
    return (
      part.id.includes(partType) ||
      (part.place_type && part.place_type.includes(partType))
    );
  }

  function getShortCode(part, index) {
    if (part.properties && part.properties.short_code) {
      return getShortCode(part.properties, index);
    }

    let shortCode = part.short_code || '';

    if (shortCode.includes('-')) {
      shortCode = shortCode.split(/-/)[index];
    }

    return shortCode.toUpperCase();
  }

  function setParts(parts, result) {
    $.each(parts, function (_i, part) {
      if (isType(part, 'place')) {
        result.city = part.text;
      }

      if (isType(part, 'postcode')) {
        result.postal_code = part.text;
      }

      if (isType(part, 'region')) {
        result.state_long_name = part.text;
        result.state_short_name = getShortCode(part, 1);
      }

      if (isType(part, 'country')) {
        result.country_long_name = part.text;
        result.country_short_name = getShortCode(part, 0);
      }
    });
  }

  let placeName = data.place_name;

  if (data.text) {
    // Override place name only if
    // 1. It's not the same word for place, state and country
    // 2. There's a city already set
    if (this.city && this.state_long_name && this.country_long_name) {
      if (
        this.city !== this.state_long_name ||
        this.state_long_name !== this.country_long_name
      ) {
        placeName = [
          data.text,
          this.state_long_name,
          this.country_long_name,
        ].join(', ');
      }

      this.city = data.text;
    }
  }

  this.displayName = placeName;
  this.name = placeName;
  this.id = data.id;
  this.latitude = data.geometry.coordinates[1];
  this.longitude = data.geometry.coordinates[0];
};

export default MapboxResult;
