import { IQService } from 'angular';
import Flash from 'legacy/flash';
import Message from 'legacy/message';

/**
 * Injects an HTTP Interceptor that displays our Flash error bar for any requests that fail.
 * Requests can opt-out of this functionality by setting 'suppressFailureMessage' to 'true'.
 */
export default function HttpInterceptorService($q: IQService) {
  return {
    responseError: function (response: any) {
      // a status code of 0 means that the code aborted the XMLHttpRequest or forced it to timeout so its not
      // technically an error
      if (!response.config.suppressFailureMessage && response.status !== 0) {
        const message = response && response.data && response.data.message;
        Flash.setError(message || Message.Error.Generic);
      }

      return $q.reject(response);
    },
  };
}
