import { IScope, ITimeoutService } from 'angular';
import $ from 'legacy/jquery';
import Template from '../templates/gh_modal.html';

interface ModalScope extends IScope {
  cancel: () => void;
  $dismiss: (action: string) => void;
}

export default function ghModal($timeout: ITimeoutService) {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    link: function (scope: ModalScope, element: any) {
      const dialog = $(element).parents('.modal-dialog');
      dialog.hide();
      $('body').css('overflow', 'hidden');

      $timeout(function () {
        const offset = ($(window).height() - dialog.height()) / 3;
        dialog.css('margin-top', offset);
        dialog.draggable({ handle: '.modal-header' });
        dialog.show();
      }, 1);

      scope.$on('$destroy', function () {
        $('body').css('overflow', 'inherit');
      });

      scope.cancel = function () {
        scope.$dismiss('cancel');
      };
    },
    template: Template,
  };
}
