/*
  eslint-disable no-use-before-define
 */
// eslint-disable-next-line no-restricted-imports
import _ from 'underscore';
import Ajax from 'legacy/ajax';
// @ts-expect-error - TS7016 - Could not find a declaration file for module
import AjaxSelectBox from 'legacy/ajax_select_box';
import Config from 'legacy/config';
// @ts-expect-error - TS7016 - Could not find a declaration file for module
import Datepicker from 'legacy/datepicker';
import editableFieldControl from 'legacy/editable_field_control';
import Flash from 'legacy/flash';
import $ from 'legacy/jquery';
import { Validator } from 'legacy/validation';

type Container = string | HTMLElement | Document | JQuery | null;

const CustomFields = (function () {
  function register(
    container: Container,
    customFieldTypeEvents?: Record<string, string>
  ) {
    container = container || document;

    $(container)
      .find('.custom-field-control')
      .each(function (this: JQuery) {
        const $control = $(this);

        editableFieldControl($control, {
          initializeFieldValue: false,
          autoCollapse: false,
        });

        const afterValidateCallback = getAfterValidateCallback(
          $control,
          customFieldTypeEvents
        );
        $control.validateOn(
          'editableFieldUpdated',
          validatorFor($control),
          afterValidateCallback
        );
      });

    $(container)
      .find('.custom-field-input')
      .each(function (this: JQuery) {
        const field = $(this);

        switch (field.data('type')) {
          case 'date':
            initializeDateField(field);
            break;
          case 'user':
            initializeUserField(field);
            break;
        }
      });
  }

  function getAfterValidateCallback(
    $control: JQuery,
    opts?: Record<string, string>
  ) {
    if (!opts) {
      return save;
    }

    const fieldType = $control.data('type') as string;
    if (opts.hasOwnProperty(fieldType)) {
      return function customCallback() {
        triggerEvent(opts[fieldType], $control);
      };
    }

    return save;
  }

  function triggerEvent(eventName: string, $control: JQuery) {
    $(document).trigger(eventName, $control);
  }

  function initializeDateField(field: JQuery) {
    Datepicker.init(field);
  }

  function initializeUserField(field: JQuery) {
    new AjaxSelectBox({
      selectBox: field,
    });
  }

  function save(this: JQuery) {
    const control = $(this);
    const form = control.find('form');
    const label = control.find('.collapsed-field-text');

    const supportsDependency = $('.custom-field-control').hasClass(
      'support-custom-field-dependency'
    );

    // Specific Case to Template Job Info Page
    const templateToggleOnJobInfo =
      $('[data-provides="edit-job-info"]').length > 0 &&
      $('[data-provides="template_toggle"]').length > 0;

    Ajax.submit<{ display_html?: string }>({
      formSelector: form,
      success: function (response) {
        control.trigger('greenhouse.collapse');
        label.html(response?.display_html || '--');

        /* Support refreshing page when saving dependency */
        if (supportsDependency) {
          window.location.reload();
        }

        if (templateToggleOnJobInfo) {
          $(document).trigger('job_info_required_fields_updated');
        }
      },
    });
  }

  function validate(container: Container) {
    const validator = new Validator();
    const inputFinder = [
      'input.custom-field-input:visible',
      'textarea.custom-field-input:visible',
      'select.custom-field-input',
      'input.custom-field-input.select2',
    ];
    const customFieldInputs = $(container).find(inputFinder.join(', '));

    // Specific Case to Template Job Info Page
    const isTemplateJobOnJobInfo =
      $('[data-provides="edit-job-info"]').length > 0 &&
      $('[data-provides="template_label"]').length > 0;
    const nullable =
      isTemplateJobOnJobInfo && container && $(container).data('nullable');

    customFieldInputs.each(function (this: JQuery) {
      const input = $(this);
      const type = input.data('type');
      const required = input.data('required');

      validator.validate(input);

      if (nullable || !required || customFieldSelectIsNotVisible(input)) {
        validator.allowEmpty();
      }

      if (
        [
          'short_text',
          'long_text',
          'yes_no',
          'single_select',
          'multi_select',
          'attachment',
          'user',
        ].indexOf(type) >= 0
      ) {
        validator.withMessage('This field is required').notBlank();
      } else if (type === 'url') {
        validator.withMessage('Enter a valid URL').url();
      } else if (
        type === 'currency' ||
        type === 'number' ||
        type === 'currency_range' ||
        type === 'number_range'
      ) {
        // Due a limitation with the data warehouse, custom field numeric values
        // shouldn't exceed 28 digits. See GREEN-34787 for more context.
        validator
          .withMessage('Enter a valid number')
          .decimalWithMaxPrecision(28);
      } else if (type === 'date') {
        validator
          .withMessage(
            'Enter a valid date of the format ' +
              Config.TimeZone.dateFormat('date')
          )
          .dateFormat();
      } else {
        Flash.setError(
          "We're sorry, but there was an error processing this form."
        );
        // eslint-disable-next-line no-console
        console.error("Unknown field type: '%s'", type);
        validator.fail();
      }
    });

    $(container)
      .find('.custom-field-unit[data-required=true]')
      .each(function (this: JQuery) {
        const input = $(this);
        const value = input.val();
        const blank = $.trim(value).length === 0;

        // Since the unit shares the same parent element as the text input, the validation will replace each other
        // So it will only replace when it is blank. Else remove the error on the unit drop down only
        if (blank && !customFieldSelectIsNotVisible(input)) {
          validator
            .validate(input)
            .withMessage('This field is required')
            .notBlank();
        } else {
          input.removeClass('field-error');
        }
      });

    validate_range(container);

    return validator;
  }

  function customFieldSelectIsNotVisible(el: JQuery) {
    return el.is('select') && !chznDropdownIsVisible(el);
  }

  function chznDropdownIsVisible(input: JQuery) {
    return (
      input.siblings('.chzn-container').length > 0 &&
      input.siblings('.chzn-container').is(':visible')
    );
  }

  function validate_range(container: Container) {
    $(container)
      .find('tr')
      .each(function (this: JQuery) {
        const row = $(this);

        if (
          row.find('.custom-field-input[data-type=currency_range]').length >
            0 ||
          row.find('.custom-field-input[data-type=number_range]').length > 0
        ) {
          const minValue = row.find('.custom-field-input').eq(0).val();
          const maxValue = row.find('.custom-field-input').eq(1).val();

          if (
            minValue &&
            maxValue &&
            parseInt(minValue, 10) > parseInt(maxValue, 10)
          ) {
            row.find('.custom-field-input').eq(0).val(maxValue);
            row.find('.custom-field-input').eq(1).val(minValue);
          }
        }
      });
  }

  function validatorFor(container: Container) {
    return validate.bind(null, container);
  }

  /**
   * Serializes the custom field forms in a way that's compatible with the Rails attr_accessible mechanism.  Produces
   * a hash that looks like:
   *
   * <namePrefix>: {
   *   'custom_field_values_attributes' => {
   *     '0' => {
   *       'custom_field_id' => 1,
   *       'value' => 123,
   *       'unit' => 'USD'
   *     }
   *   }
   * }
   * //referral_details"=>{"custom_field_values_attributes"=>{"0"=>{"id"=>"", "custom_field_id"=>"16", "value"=>""},
   *
   * @param namePrefix Probably what you passed into `custom_field_tag` on the Rails side
   */
  function serializeForRails(selector: string, namePrefix: string) {
    const customFieldData = {
      custom_field_values_attributes: {},
    };

    _(
      $(selector)
        .find("[name^='" + namePrefix + "[']")
        .serializeArray()
    ).each(function (field) {
      if (!field.value || field.value.trim().length === 0) {
        return;
      }

      const regex = new RegExp(
        namePrefix +
          '\\[custom_field_values_attributes\\]\\[(\\d+)\\]\\[([^\\]]+)\\](?:\\[\\])?'
      );
      const match = field.name.match(regex);

      if (!match) {
        throw 'Encountered invalid custom field element' + field;
      }

      const index: string = match[1];
      const attribute: string = match[2];
      // @ts-expect-error - TS7053 No index signature
      let fieldData = customFieldData.custom_field_values_attributes[index];

      if (!fieldData) {
        fieldData = {};
        // @ts-expect-error - TS7053 No index signature
        customFieldData.custom_field_values_attributes[index] = fieldData;
      }

      const existingValue = fieldData[attribute];
      if (existingValue) {
        fieldData[attribute] = [].concat(fieldData[attribute], field.value);
      } else {
        fieldData[attribute] = field.value;
      }
    });

    return customFieldData;
  }

  return {
    register: register,
    validate: validate,
    validatorFor: validatorFor,
    save: save,
    serializeForRails: serializeForRails,
  };
})();

export default CustomFields;
