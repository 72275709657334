import Handlebars, { HelperOptions } from 'handlebars';

const compareOperators = {
  '==': function (l: unknown, r: unknown) {
    // eslint-disable-next-line eqeqeq
    return l == r;
  },
  '===': function (l: unknown, r: unknown) {
    return l === r;
  },
  '!=': function (l: unknown, r: unknown) {
    // eslint-disable-next-line eqeqeq
    return l != r;
  },
  '!==': function (l: unknown, r: unknown) {
    return l !== r;
  },
  '<': function (l: number | string, r: number | string) {
    return l < r;
  },
  '>': function (l: number | string, r: number | string) {
    return l > r;
  },
  '<=': function (l: number | string, r: number | string) {
    return l <= r;
  },
  '>=': function (l: number | string, r: number | string) {
    return l >= r;
  },
  typeof: function (l: unknown, r: unknown) {
    return typeof l === r;
  },
};

Handlebars.registerHelper(
  'compare',
  function (lvalue, operator, rvalue, options) {
    if (arguments.length < 3) {
      throw new Error("Handlebars Helper 'compare' needs 2 parameters");
    }

    if (options === undefined) {
      options = rvalue;
      rvalue = operator;
      operator = '===';
    }

    if (!compareOperators[operator as keyof typeof compareOperators]) {
      throw new Error(
        "Handlebars Helper 'compare' doesn't know the operator " + operator
      );
    }

    const result = compareOperators[operator as keyof typeof compareOperators](
      lvalue,
      rvalue
    );
    if (result) {
      // @ts-expect-error - TS2683 - this is implicitly any
      return options.fn(this);
    } else {
      // @ts-expect-error - TS2683 - this is implicitly any
      return options.inverse(this);
    }
  }
);

const expressionRegistry = {
  not: function (left: number | string, right: number | string) {
    // eslint-disable-next-line eqeqeq
    return left != right;
  },
  '>': function (left: number | string, right: number | string) {
    return left > right;
  },
  '<': function (left: number | string, right: number | string) {
    return left < right;
  },
  '>=': function (left: number | string, right: number | string) {
    return left >= right;
  },
  '<=': function (left: number | string, right: number | string) {
    return left <= right;
  },
  '===': function (left: number | string, right: number | string) {
    return left === right;
  },
  '!==': function (left: number | string, right: number | string) {
    return left !== right;
  },
  in: function (left: number | string, right: Array<unknown> | string) {
    if (!Array.isArray(right)) {
      right = right.split(',');
    }
    return right.indexOf(left) !== -1;
  },
} as const;

const isHelper = function (this: unknown, ...args: Array<unknown>) {
  const left = args[0] as number | string;

  if (args.length === 2) {
    const options = args[1] as unknown as HelperOptions;
    if (left) {
      return options.fn(this);
    }
    return options.inverse(this);
  } else if (args.length === 3) {
    const right = args[1];
    const options = args[2] as unknown as HelperOptions;
    // eslint-disable-next-line eqeqeq
    if (left == right) {
      return options.fn(this);
    }
    return options.inverse(this);
  } else if (args.length === 3) {
    const operator = args[1] as keyof typeof expressionRegistry;
    const right = args[2] as never;
    const options = args[3] as unknown as HelperOptions;

    if (expressionRegistry[operator](left, right)) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  }
};

Handlebars.registerHelper('is', isHelper);

export default Handlebars;
