const PeliasResult = function (data) {
  let placeName = data.properties.label;

  this.id = data.properties.id;
  this.city = data.properties.name;

  this.state_long_name = data.properties.region;
  this.state_short_name = data.properties.region_a;

  this.country_long_name = data.properties.country;
  this.country_short_name = data.properties.country_a;

  if (this.city && this.state_long_name && this.country_long_name) {
    if (
      this.city !== this.state_long_name &&
      this.state_long_name !== this.country_long_name
    ) {
      placeName = [
        this.city,
        this.state_long_name,
        this.country_long_name,
      ].join(', ');
    }
  }

  this.displayName = placeName;
  this.name = placeName;
  this.latitude = data.geometry.coordinates[1];
  this.longitude = data.geometry.coordinates[0];
};

export default PeliasResult;
