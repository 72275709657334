const MapboxDummyResult = function (name) {
  this.city = null;
  this.country_long_name = null;
  this.country_short_name = null;
  this.displayName = name;
  this.id = 'dummy';
  this.latitude = null;
  this.longitude = null;
  this.name = name;
  this.postal_code = null;
  this.state_long_name = null;
  this.state_short_name = null;
};

export default MapboxDummyResult;
