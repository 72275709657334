/**
 * Closely inspired by
 * https://github.com/xrd/ng-rails-csrf/blob/8b9a2535f8d39ec1ed5b817fa1d48385056e48f8/vendor/assets/javascripts/ng-rails-csrf.js
 */
import angular, { IHttpProvider } from 'angular';

angular.module('ng-rails-csrf', []).config([
  '$httpProvider',
  function ($httpProvider: IHttpProvider) {
    const getToken = function () {
      return document
        .querySelector('meta[name="csrf-token"]')
        ?.getAttribute('content');
    };
    const updateToken = function () {
      const headers = $httpProvider.defaults.headers?.common;
      const token = getToken();
      if (token) {
        headers['X-CSRF-TOKEN'] = getToken;
        headers['X-Requested-With'] = 'XMLHttpRequest';
      }
    };
    updateToken();
  },
]);
