const Select2Tag = {
  tags: function (selector: string) {
    $(selector).each(function () {
      $(this).select2({
        tags: $(this).data('tags'),
      });
    });
  },
};

export default Select2Tag;
