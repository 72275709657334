/* eslint-disable no-use-before-define */
import MapboxDummyResult from './dummy_result';
import MapboxRequest from './request';

const MapboxStorableResult = function (autoCompletedResult, callback) {
  const autoCompletedName = autoCompletedResult.name;

  this.ensureStorable = function () {
    const type = autoCompletedResult.id.split('.')[0];
    const request = new MapboxRequest({
      locationType: type,
    });
    const term = [
      autoCompletedResult.longitude,
      autoCompletedResult.latitude,
    ].join(',');

    request.permanent(term, invokeCallback);
  };

  function invokeCallback(results) {
    if (results.length > 0) {
      callbackWithResult(results);
    } else {
      callbackWithDummyResult();
    }
  }

  function callbackWithResult(results) {
    // Let's use the name the user picked
    const result = results[0];

    result.city = autoCompletedResult.city;
    result.country_long_name = autoCompletedResult.country_long_name;
    result.country_short_name = autoCompletedResult.country_short_name;
    result.displayName = autoCompletedResult.displayName;
    result.name = autoCompletedResult.name;
    result.postal_code = autoCompletedResult.postal_code;
    result.state_long_name = autoCompletedResult.state_long_name;
    result.state_short_name = autoCompletedResult.state_short_name;

    callback(result);
  }

  function callbackWithDummyResult() {
    const result = new MapboxDummyResult(autoCompletedName);
    callback(result);
  }
};

export default MapboxStorableResult;
