import angular, { IQProvider } from 'angular';
import 'angular-local-storage';
import 'angular-sanitize';
import 'angular-ui-router';
import 'angular-ui-router-tabs';
import 'angular-ui-bootstrap';
import 'ui-select';
import 'ui-select/dist/select.min.css';
import './shared/ng_rails_csrf';
import Message from 'legacy/message';
import HttpInterceptorService from './services/http_interceptor_service';
import TaggableService from './services/taggable_service';
import StorableRequest from './shared/storable_request';
import chosen from './directives/chosen';
import ghClickAndLock from './directives/ghClickAndLock';
import ghDropdown from './directives/ghDropdown';
import ghEnter from './directives/ghEnter';
import ghInputWithButton from './directives/ghInputWithButton';
import ghModal from './directives/ghModal';
import ghNaturalNumbersOnly from './directives/ghNaturalNumbersOnly';
import ghSelectionList from './directives/ghSelectionList';
import ghSortable from './directives/ghSortable';

export default function configureGreenhouseCommon() {
  angular
    .module('greenhouse.common', [
      'ui.router',
      'ui.router.tabs',
      'ui.select',
      'ui.bootstrap',
      'ng-rails-csrf',
      'LocalStorageModule',
      'ngSanitize',
    ])
    // Suppress errors on unhandled rejection reasons from AngularJS 1.6
    .config([
      '$qProvider',
      function ($qProvider: IQProvider) {
        $qProvider.errorOnUnhandledRejections(false);
      },
    ])
    .factory('HttpInterceptorService', ['$q', HttpInterceptorService])
    .factory('Taggable', ['$timeout', TaggableService])
    .factory('StorableRequest', [
      '$http',
      'localStorageService',
      StorableRequest,
    ])
    .directive('chosen', ['$timeout', chosen])
    .directive('ghClickAndLock', [ghClickAndLock])
    .directive('ghDropdown', ['$timeout', ghDropdown])
    .directive('ghEnter', [ghEnter])
    .directive('ghInputWithButton', [ghInputWithButton])
    .directive('ghModal', ['$timeout', ghModal])
    .directive('ghNaturalNumbersOnly', [ghNaturalNumbersOnly])
    .directive('ghSelectionList', ['$timeout', ghSelectionList])
    .directive('ghSortable', ['$timeout', ghSortable])
    .constant('Message', Message);
}
