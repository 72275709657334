import Config from 'legacy/config';
import $ from 'legacy/jquery';
import 'legacy/jquery/jq_ext';
import { Validate } from 'legacy/validation';
import { contentSecurityPolicy } from 'legacy/util/prevent_default_placeholder_anchor_tags';

export interface IModal {
  container: () => JQuery;
  registerEvents: () => void;
  registerOpen: () => void;
  open: () => void;
  close: () => void;
  destroy: () => void;
  clearForm: () => void;
  setTitle: (title: string) => void;
  getTitle: () => void;
  center: () => void;
  top: () => void;
  setWidth: (width: number) => void;
  adjustHeight: () => void;
}

type Callback = (modal: IModal) => void;

export type Options = Partial<{
  openWith: string;
  /** single function or array of functions that are ran BEFORE the modal is opened **/
  beforeOpen: Callback | Array<Callback>;
  /** single function or array of functions that are ran AFTER the modal is opened **/
  afterOpen: Callback | Array<Callback>;
  beforeClose: Callback;
  closeOnEscape: boolean;
  preventCloseOnOutsideClick: boolean;
  validateWith: () => void;
  openIfTrue: () => boolean;
  onSubmit: (e: any) => void;
  confirmWith: string;
  onConfirm: () => void;
  openWithFilter: string;
  config: any;
}>;

const Modal = (function () {
  function Modal(this: any, selector: string, options: Options) {
    this.selector = selector;
    this.modal = $(selector);
    this.options = options || {};
    this.openWithContext = null;

    // wrap 'beforeOpen' and 'afterOpen' in an array, if it isn't already
    if (this.options.beforeOpen && !$.isArray(this.options.beforeOpen)) {
      this.options.beforeOpen = [this.options.beforeOpen];
    }

    if (this.options.afterOpen && !$.isArray(this.options.afterOpen)) {
      this.options.afterOpen = [this.options.afterOpen];
    }

    this.registerEvents();
  }

  Modal.prototype.container = function () {
    return this.modal;
  };

  Modal.prototype.registerEvents = function () {
    const that = this;

    this.modal.on('greenhouse.heightChanged', function () {
      that.adjustHeight();
    });

    this.modal.on(
      'keydown',
      function (this: any, e: JQuery.Event) {
        if (this.options.closeOnEscape && e.keyCode === $.ui.keyCode.ESCAPE) {
          this.close();
          e.stopPropagation();
        }
      }.bind(this)
    );

    // Register jquery ui titlebar event
    this.modal
      .siblings('.ui-dialog-titlebar')
      .find('.x')
      .unbind('click')
      .click($.proxy(this.close, this));
    this.modal
      .find('.x, .cancel')
      .unbind('click')
      .click($.proxy(this.close, this))
      .on('click', contentSecurityPolicy.hrefPreventDefault);
    this.modal.find('.chzn-select').chosen(Config.Chosen.Default);

    this.modal.on(
      'click',
      'a[href="#"]',
      contentSecurityPolicy.hrefPreventDefault
    );

    this.registerOpen();

    if (
      typeof this.options.validateWith === 'function' &&
      typeof this.options.onSubmit === 'function'
    ) {
      this.modal
        .find('form')
        .validateOn(
          'submit',
          this.options.validateWith,
          this.options.onSubmit,
          { scroll: false }
        );
    } else if (typeof this.options.onSubmit === 'function') {
      this.modal.find('form').on('submit', this.options.onSubmit);
    } else if (
      this.options.confirmWith &&
      typeof this.options.onConfirm === 'function'
    ) {
      $(this.options.confirmWith).on('click', this.options.onConfirm);
    }
  };

  Modal.prototype.registerOpen = function () {
    const modal = this;
    const $openWith = $(this.options.openWith);
    const $openWithFilter = $(this.options.openWithFilter);
    const openWithEvent = this.options.openWithEvent;

    if ($openWith.length > 0) {
      // use a namespaced event to prevent unbinding click events from other controls
      const event = 'click.' + this.selector;

      $openWith
        .unbind(event)
        .on(
          event,
          this.options.openWithFilter,
          function (this: any, e: JQuery.Event) {
            modal.openWithContext = this;

            if (
              modal.options.openIfTrue === undefined ||
              modal.options.openIfTrue(this)
            ) {
              openHandler.call(this);
            }

            e.stopPropagation();
          }
        );

      if ($openWithFilter.attr('href') === '#') {
        $openWithFilter.on('click', contentSecurityPolicy.hrefPreventDefault);
      } else if ($openWith.attr('href') === '#') {
        $openWith.on('click', contentSecurityPolicy.hrefPreventDefault);
      }

      // Open modal without a selector
      if (openWithEvent) {
        $(window.document)
          .unbind(openWithEvent)
          .on(openWithEvent, function (this: any) {
            openHandler.call(this);
          });
      }
    }

    function openHandler(this: any) {
      const runCallbacks = function (
        this: any,
        callbackArray: Array<(any: any) => void>
      ) {
        if (callbackArray) {
          for (let i = 0; i < callbackArray.length; i++) {
            const callback = callbackArray[i];
            if (typeof callback === 'function') {
              callback.call(this, modal);
            }
          }
        }
      }.bind(this);

      runCallbacks(modal.options.beforeOpen);
      modal.open();
      runCallbacks(modal.options.afterOpen);

      modal.adjustHeight();
    }
  };

  Modal.prototype.open = function () {
    this.modal.dialog(Config.Modal.custom(this.options.config));
    this.header = this.modal.siblings('.ui-dialog-titlebar');
    this.title = this.title || this.modal.data('modal-title');
    this.setTitle(this.title);

    if (!this.options.preventCloseOnOutsideClick) {
      $('.ui-widget-overlay').on('click', this.close.bind(this));
    }

    // After widget is initialized, replace jquery ui button and register event
    $('.ui-dialog-titlebar-close').addClass('x').attr('href', '#');
    this.modal
      .siblings('.ui-dialog-titlebar')
      .find('.x')
      .unbind('click')
      .click($.proxy(this.close, this))
      .on('click', contentSecurityPolicy.hrefPreventDefault);
    this.modal.find('.x').blur();
    this.modal.find('.autofocus').focus();
    this.modal.find('.autoselect').select();

    this.adjustHeight();
  };

  Modal.prototype.close = function () {
    if (typeof this.options.beforeClose === 'function') {
      if (this.options.beforeClose.call(this) === false) {
        return false;
      }
    }

    if (this.modal.hasClass('ui-dialog-content')) {
      this.modal.dialog('close');
    }
  };

  Modal.prototype.destroy = function () {
    if (this.modal.dialog('instance')) {
      this.modal.dialog('destroy').remove();
    }
  };

  Modal.prototype.clearForm = function () {
    this.modal.find('form')[0].reset();
    this.modal.find('.chzn-select').trigger('liszt:updated');
    Validate.clearError(this.modal.find('input, select, textarea'));
  };

  Modal.prototype.setTitle = function (title: string) {
    this.title = title;
    if (this.header) {
      this.header.find('.ui-dialog-title').text(title);
    }
  };

  Modal.prototype.getTitle = function () {
    return this.title;
  };

  Modal.prototype.center = function () {
    if (this.modal.hasClass('ui-dialog-content')) {
      this.modal.dialog('option', 'position', {
        my: 'center',
        at: 'center',
        of: window,
      });
    }
  };

  Modal.prototype.top = function () {
    if (this.modal.hasClass('ui-dialog-content')) {
      this.modal.dialog('option', 'position', {
        my: 'top',
        at: 'top',
        of: window,
      });
    }
  };

  Modal.prototype.setWidth = function (width: number) {
    this.modal.dialog('option', 'width', width);
  };

  /**
   * Adjusts the modal's height based on the window height if there is an element
   * with class "scrollable" in the modal body.
   */
  Modal.prototype.adjustHeight = function () {
    if (this.options.config && this.options.config.doNotAdjustHeight) {
      return;
    }

    const scrollableContent = this.modal.find('.scrollable:visible');

    if (scrollableContent.length === 1) {
      this.modal.css('maxHeight', 'none');
      scrollableContent.css('height', 'auto');

      const height = this.modal.height();
      const maxHeight = $(window).height() - 200;

      if (height > maxHeight) {
        this.modal.css('maxHeight', maxHeight);

        scrollableContent.css({
          height: maxHeight - (height - scrollableContent.height()),
          overflow: 'auto',
        });
      }
    }

    this.center();
  };

  Modal.clearForm = function (modal: IModal) {
    modal.clearForm();
  };

  return Modal;
})() as any as {
  clearForm: (modal: IModal) => void;
  new (selector: string, options: Options): IModal;
};

export default Modal;
