import { withLocalePrefix } from 'shared/utils/translation';
const t = withLocalePrefix('file_uploads.dropbox');

const DropboxChooser = function (args) {
  let options;
  let $trigger;

  function init(args) {
    options = args || {};
    $trigger = options.$trigger;
    options.uploadComplete = options.uploadComplete || function () {};

    $trigger.on('click', call);
  }

  function uploadSuccess(files) {
    const file = files[0];
    options.$messages.hide();
    options.uploadComplete({
      filename: file.name,
      url: file.link,
    });
  }

  function call() {
    const { Dropbox } = window;
    if (!Dropbox) {
      options.$messages.text(t('try_again')).show();
      return;
    }

    if (!Dropbox.isBrowserSupported()) {
      options.$messages.text(t('without_browser_support')).show();
      $trigger.remove();
      return;
    }

    Dropbox.choose({
      success: uploadSuccess,
      cancel: options.uploadCancelled,
      linkType: 'direct',
      extensions: ['documents', 'text'],
    });
    options.uploadStarted();
    options.$messages.text(t('loading_attachment')).show();
    options.$messages.show();
  }

  init(args);
};

export default DropboxChooser;
