import Config from 'legacy/config';
import $ from 'legacy/jquery';

const Datepicker = {
  init: function (selector, options) {
    const datepicker = $(selector);
    datepicker.datepicker(
      $.extend(options, {
        dateFormat: Config.TimeZone.dateFormat('datepicker'),
      })
    );
    datepicker.datepicker('setDate', datepicker.val());
    $('#ui-datepicker-div').addClass('datepicker');
  },
};

export default Datepicker;
