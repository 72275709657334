import AjaxModal from 'legacy/ajax_modal';
import $ from 'legacy/jquery';

const LegacyInclusionNudge = {
  show: function () {
    $('.inclusion-nudge').slideDown(function () {
      // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
      $(this).addClass('visible');

      new AjaxModal('inclusion_initiative_modal', {
        // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
        openWith: $(this).find('.inclusion-initiative-link'),
        config: { width: 700 },
        centerOnLoad: true,
      });
    });
  },
  showLater: function (delay: number) {
    setTimeout(LegacyInclusionNudge.show, delay);
  },
  hide: function () {
    $('.inclusion-nudge').hide().removeClass('visible');
  },
} as const;

export default LegacyInclusionNudge;
