import _ from 'underscore';
import Ajax from 'legacy/ajax';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'legacy/ajax_select_box'.
import AjaxSelectBox from 'legacy/ajax_select_box';
import Handlebars from 'legacy/handlebars';
import $ from 'legacy/jquery';
import 'legacy/jquery/jq_ext';

// @ts-expect-error - TS2339 - Property 'reconcile_tags_path' does not exist on type 'Window & typeof globalThis'.
const reconcileTagsPath = window.reconcile_tags_path;

const TagControl = (function () {
  // @ts-expect-error - TS7034 - Variable '$tagField' implicitly has type 'any' in some locations where its type cannot be determined.
  let $tagField;
  // @ts-expect-error - TS7034 - Variable '$modifyTags' implicitly has type 'any' in some locations where its type cannot be determined.
  let $modifyTags;
  // @ts-expect-error - TS7034 - Variable '$selectedTags' implicitly has type 'any' in some locations where its type cannot be determined.
  let $selectedTags;
  // @ts-expect-error - TS7034 - Variable '$addTagsButtonContainer' implicitly has type 'any' in some locations where its type cannot be determined.
  let $addTagsButtonContainer;
  // @ts-expect-error - TS7034 - Variable '$addTagsSelectContainer' implicitly has type 'any' in some locations where its type cannot be determined.
  let $addTagsSelectContainer;
  // @ts-expect-error - TS7034 - Variable '$tagHiddenField' implicitly has type 'any' in some locations where its type cannot be determined.
  let $tagHiddenField;
  let $doneTaggingButton;
  // @ts-expect-error - TS7034 - Variable '$noInterviewerTags' implicitly has type 'any' in some locations where its type cannot be determined.
  let $noInterviewerTags;
  const template = Handlebars.compile(
    ' <div style="float:left;display:inline-block;padding:2px;"><a href="#" class="tiny-button" style="margin-right: 5px;" ctagid="{{ctag_id}}">{{name}}</a></div>'
  );

  function init(prefix?: string | null) {
    prefix = prefix || '';
    prefix += ' ';

    $tagField = $(prefix + '.tag-field');
    $selectedTags = $(prefix + '.selected-tags');
    $addTagsButtonContainer = $(prefix + '.add-tags-button-container');
    $addTagsSelectContainer = $(prefix + '.add-tags-select-container');
    $tagHiddenField = $(prefix + '#tags');
    $doneTaggingButton = $(prefix + '.done-tagging-button');
    $modifyTags = $(prefix + '.modify-tags');
    $noInterviewerTags = $(prefix + '.no-interviewer-tags');

    if (!$tagField.data('select2')) {
      new AjaxSelectBox({ selectBox: $tagField });
    }

    $modifyTags.offOn('click', function () {
      // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
      $(this).hide();
      // @ts-expect-error - TS7005 - Variable '$addTagsButtonContainer' implicitly has an 'any' type.
      $addTagsButtonContainer.hide();
      // @ts-expect-error - TS7005 - Variable '$addTagsSelectContainer' implicitly has an 'any' type.
      $addTagsSelectContainer.show();
    });

    $doneTaggingButton.offOn('click', reconcile);

    function reconcile() {
      Ajax.post({
        url: reconcileTagsPath,
        data: { tags: getSelectedOptions() },
        success: function () {
          renderCollapsedView();
        },
      });
    }
  }

  function getSelectedOptions() {
    // @ts-expect-error - TS7005 - Variable '$tagField' implicitly has an 'any' type.
    const data = $tagField.select2('data');
    let tempId = 0;

    // @ts-expect-error - TS7006 - Parameter 'object' implicitly has an 'any' type.
    return _.map<any, any>(data, function (object) {
      return {
        id: object.id === parseInt(object.id, 10) ? object.id : --tempId,
        name: object.text,
      };
    });
  }

  function renderCollapsedView() {
    const sortedTags = _(getSelectedOptions()).sortBy(name);

    // @ts-expect-error - TS7005 - Variable '$selectedTags' implicitly has an 'any' type.
    $selectedTags.empty();

    if (sortedTags.length > 0) {
      // @ts-expect-error - TS7005 - Variable '$noInterviewerTags' implicitly has an 'any' type.
      $noInterviewerTags.hide();
    } else {
      // @ts-expect-error - TS7005 - Variable '$noInterviewerTags' implicitly has an 'any' type.
      $noInterviewerTags.show();
    }

    const selectedTagHtml = sortedTags
      .map(function (tag) {
        return template({ name: tag.name, ctag_id: tag.id });
      })
      .join(' ');

    if (selectedTagHtml) {
      // @ts-expect-error - TS7005 - Variable '$selectedTags' implicitly has an 'any' type.
      $selectedTags.append(selectedTagHtml);
    }

    // @ts-expect-error - TS7005 - Variable '$addTagsButtonContainer' implicitly has an 'any' type.
    $addTagsButtonContainer.show();
    // @ts-expect-error - TS7005 - Variable '$addTagsSelectContainer' implicitly has an 'any' type.
    $addTagsSelectContainer.hide();
    // @ts-expect-error - TS7005 - Variable '$modifyTags' implicitly has an 'any' type.
    $modifyTags.show();
  }

  function name(tag: any) {
    return tag.name.toLowerCase();
  }

  function updateFormField() {
    if ($tagHiddenField.length > 0) {
      // @ts-expect-error - TS7005 - Variable '$tagHiddenField' implicitly has an 'any' type.
      $tagHiddenField.val(JSON.stringify(getSelectedOptions()));
    }
  }

  // Can't init on page load because Bulk Actions 'Edit Tags' DOM is not ready yet */
  return {
    initialize: init,
    getSelectedOptions: getSelectedOptions,
    updateFormField: updateFormField,
    renderCollapsedView: renderCollapsedView,
  };
})();

export default TagControl;
