import moment from 'moment-timezone';
import _ from 'underscore';
import Config from 'legacy/config';
import FullCalendar from 'legacy/fullcalendar/gh_fullcalendar';
import $ from 'legacy/jquery';
import { mountInterviewerEvent } from '../../suggest_interview_times/mount';
import { setTimeZoneLabel } from 'shared/full_calendar_helpers';
import { addMomentLocale } from 'shared/utils/moment_locale_helper';
import t, { tList } from 'shared/utils/translation';

addMomentLocale();

const AvailabilityCalendar = (function () {
  /**
   * mode is one of
   *   - edit_proposed - editing suggested times
   *   - edit_candidate - editing candidate times from within greenhouse
   *   - edit_candidate_public - editing candidate times from public candidate page
   */
  function Calendar(container, mode, timeZoneId) {
    this.container = container;
    this.readOnly = this.container.data('read-only');
    this.mode = mode;
    this.events = [];
    this.lastId = 0;

    // Candidate facing instances of this calendar pass in their local time zone id as a parameter
    // Greenhouse user facing instances will use the data attribute
    this.timeZoneId =
      timeZoneId || this.container.data('preferred-time-zone-id');

    this.init();
  }

  Calendar.prototype.init = function () {
    const options = _.extend(Config.FullCalendar.Default, {
      select: $.proxy(this.onSelect, this),
      eventResize: $.proxy(updateEvent, this),
      eventDrop: $.proxy(updateEvent, this),
      events: function (_start, _end, _timezone, callback) {
        callback(this.events);
      }.bind(this),
      eventRender: $.proxy(this.renderEvent, this),
      defaultDate: this.container.data('default-date'),
      selectable: !this.readOnly,
      titleFormat: Config.TimeZone.dateFormat('date_pretty'),
      columnFormat: Config.TimeZone.dateFormat(
        'date_without_year_with_day_of_week'
      ),
      slotLabelFormat: Config.TimeZone.timeFormat('time_hide_minutes_when_0'),
      timeFormat: Config.TimeZone.timeFormat('time_hide_minutes_when_0'),
      header: {
        left: 'title',
        right: 'prev,next today',
      },
      timezone: this.timeZoneId,
      height: 500,
      defaultTimedEventDuration: '00:15:00',
      // @ts-expect-error - TS2339 - Property 'Greenhouse' does not exist on type 'Window & typeof globalThis'.
      lang: window.Greenhouse.Translation.locale,
      monthNames: tList('date.month_names', {}, true),
      monthNamesShort: tList('date.abbr_month_names', {}, true),
      dayNames: tList('date.day_names', {}, true),
      dayNamesShort: tList('date.abbr_day_names', {}, true),
      buttonText: {
        today: t('spicy_scheduler.calendar.today'),
      },
      allDayText: t('spicy_scheduler.calendar.all_day'),
    });

    if (_.contains(['edit_candidate', 'edit_candidate_public'], this.mode)) {
      options.allDaySlot = false;
    }

    this.container.fullCalendar(options);

    const that = this;
    setTimeout(function () {
      setTimeZoneLabel(that.timeZoneId);
    });

    this.loadEvents(setEvents.bind(this));
  };

  Calendar.prototype.render = function () {
    this.container.fullCalendar('render');
  };

  Calendar.prototype.getEvents = function () {
    return _.chain(this.container.fullCalendar('clientEvents'))
      .map(normalizeAllDayEvents, this)
      .where({ greenhouse_type: resultType(this.mode) })
      .value();
  };

  Calendar.prototype.renderEditable = function (event, eventElement) {
    if (this.readOnly) {
      return;
    }

    FullCalendar.addRemoveButton(event, eventElement, removeEvent.bind(this));
  };

  Calendar.prototype.renderBackground = function (
    event,
    eventElement,
    view,
    msg
  ) {
    eventElement.append(
      $('<div class="description"><div>' + msg + '</div></div>')
    );
  };

  Calendar.prototype.renderInterviewer = function (event, $eventElement) {
    const eventElement = $eventElement[0];

    if (eventElement) {
      mountInterviewerEvent(eventElement, event);
    }
  };

  Calendar.prototype.renderEvent = function (event, eventElement, view) {
    const suggestedTimeText =
      t('candidate_profile.availability_modal.suggested') +
      '<br/>' +
      t('candidate_profile.availability_modal.time');

    if (
      event.greenhouse_type === 'proposed' &&
      this.mode === 'edit_candidate_public'
    ) {
      this.renderBackground(event, eventElement, view, suggestedTimeText);
    } else if (
      event.greenhouse_type === 'proposed' &&
      this.mode === 'edit_candidate'
    ) {
      this.renderBackground(event, eventElement, view, suggestedTimeText);
    } else if (event.greenhouse_type === 'interviewer') {
      this.renderInterviewer(event, eventElement, view);
    } else {
      this.renderEditable(event, eventElement, view);
    }
  };

  Calendar.prototype.transformEvent = function (event) {
    const self = this;

    event.editable = !this.readOnly;
    event.start = moment(event.start).tz(this.timeZoneId);
    event.end = moment(event.end).tz(this.timeZoneId);
    event = this.transformAllDayEvent(event);

    return event.greenhouse_type === 'proposed'
      ? transformProposed(event)
      : transformCandidate(event);

    function transformProposed(event) {
      if (self.mode !== 'edit_proposed') {
        event.editable = false;
        event.rendering = 'background';
        event.id = 'proposed';
      }

      return event;
    }

    function transformCandidate(event) {
      return event;
    }
  };

  Calendar.prototype.transformAllDayEvent = function (event) {
    if (event.allDay) {
      if (
        event.greenhouse_type === 'proposed' &&
        _.contains(['edit_candidate', 'edit_candidate_public'], this.mode)
      ) {
        event.allDay = false;
      } else {
        event.title = 'all-day';
      }
    }
    return event;
  };

  Calendar.prototype.loadEvents = function (callback) {
    if (!this.container.data('events-path')) {
      return;
    }

    $.ajax({
      url: this.container.data('events-path'),
      data: { type: this.mode === 'edit_proposed' ? 'proposed' : 'all' },
      success: function (response) {
        const events = _.map(response, this.transformEvent.bind(this));
        callback(events);
      }.bind(this),
    });
  };

  Calendar.prototype.onSelect = function (start, end) {
    const type = resultType(this.mode);
    let event = {
      start: start,
      end: end,
      greenhouse_type: type,
      allDay: !start.hasTime(),
    };
    event = this.transformAllDayEvent(event);
    addEvent.call(this, event);
    this.container.fullCalendar('unselect');
  };

  function setEvents(events) {
    this.events = events;

    refreshEvents.call(this);
  }

  function addEvent(event) {
    event.id = ++this.lastId;

    this.events.push(event);

    refreshEvents.call(this);
  }

  function removeEvent(event) {
    this.events = _(this.events).reject(function (otherEvent) {
      return event.id === otherEvent.id;
    });

    refreshEvents.call(this);
  }

  function updateEvent(event) {
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].id === event.id) {
        this.events[i] = event;
        return;
      }
    }

    refreshEvents.call(this);
  }

  function refreshEvents() {
    if (this.container.is(':visible')) {
      this.container.fullCalendar('refetchEvents');
    }
  }

  // Treat all day events as properly zoned 24 hour blocks
  function normalizeAllDayEvents(event) {
    if (event.allDay) {
      const end = event.end || event.start;

      const normalizedEvent = {
        start: moment.tz(event.start.format(), this.timeZoneId).clone(),
        end: moment.tz(end.format(), this.timeZoneId).clone(),
        greenhouse_type: event.greenhouse_type,
      };

      if (normalizedEvent.start === normalizedEvent.end) {
        normalizedEvent.end = moment(normalizedEvent.end)
          .add(1, 'days')
          .format();
      }

      return normalizedEvent;
    } else {
      return event;
    }
  }

  function resultType(mode) {
    return mode === 'edit_proposed' ? 'proposed' : 'candidate';
  }

  return Calendar;
})();

export default AvailabilityCalendar;
