/* eslint-disable no-use-before-define */

import MapboxRequest from './mapbox/request';
import MapboxStorableResult from './mapbox/storable_result';

const Mapbox = function (locationType, language, country) {
  this.geocode = function (term, callback) {
    const request = new MapboxRequest({
      locationType: locationType,
      language: language,
      country: country,
    });
    request.autoComplete(term, callback);
  };

  this.reverseGeocode = function (latitude, longitude, callback) {
    const term = [longitude, latitude].join(',');
    const request = new MapboxRequest({
      locationType: locationType,
      language: language,
      country: country,
      limit: false,
    });
    request.autoComplete(term, callback);
  };

  this.resultSelected = function (autoCompletedResult, callback) {
    // Due to MapBox's licensing terms, we need to hit the permanent
    // endpoint when getting a location that will be saved.

    const storableResult = new MapboxStorableResult(
      autoCompletedResult,
      callback
    );
    storableResult.ensureStorable();
  };
};

export default Mapbox;
