import Ajax from 'legacy/ajax';
import Flash from 'legacy/flash';

/**
 * Helper for polling for the status of BackgroundTask objects.  Can send a callback on either task success
 * or failure.
 *
 */
const BackgroundTasksPoller = (function () {
  let onComplete;
  let onFailure;
  let onEither;

  function pollForTask(
    path,
    backgroundTaskId,
    _onComplete_,
    _onFailure_,
    _onEither_
  ) {
    const poller = setInterval(pollStatus, 5000);

    onComplete = _onComplete_;
    onFailure = _onFailure_ || function () {};
    onEither = _onEither_ || function () {};

    function pollStatus() {
      Ajax.get({
        url: path,
        data: { background_task_id: backgroundTaskId },
        disableFlash: true,
        success: function (response) {
          onStatusUpdate(response, poller);
        },
        error: function (response) {
          Flash.setError(response.message);
          clearInterval(poller);
        },
      });
    }
  }

  function onStatusUpdate(response, poller) {
    if (response.task_status === 'complete') {
      onComplete(response);
      onEither(response);
      clearInterval(poller);
    } else if (response.task_status === 'failed') {
      onFailure(response);
      onEither(response);
      clearInterval(poller);
    }
  }

  return {
    pollForTask: pollForTask,
  };
})();

export default BackgroundTasksPoller;
