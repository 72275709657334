/* eslint-disable max-len, no-use-before-define, no-shadow */
/**
 *  Creates an editable field control
 *
 *  opts: {
 *    defaultText: 'something',
 *    collapseWithQuotes: true,
 *    allowBlank: false,        // true is the default value
 *    blankMessage: 'whatever', // required if allowBlank is false
 *    validateWith: myfunc,     // a custom validation function that returns a Validator or array of validators. *This will override allowBlank if both are specified*
 *    displayValue: myfunc,     // an optional function to determine the collapsed text after saving. Useful when
 *                              // your editable field is more complex than a simple text input.
 *    initializeFieldValue: true // defaults to true; specify false if the input field value should NOT be initialized
 *                                  from the collapsed text. In this case you need to set it yourself in ERB.
 *    initializeDisplayValue: true // defaults to false; specify true if the display label should be initialized
 *                                    immediately using the provided displayValue function
 *    autoCollapse: false          // defaults to true; set to false if you want to collapse the field yourself by
 *                                    triggering the greenhouse.collapse event
 *    noPencilIcon: false          // defaults to false; set to true if you want to remove the pencil icon for editing the field,
 *                                    and instead you can enter into edit mode by clicking the text field.
 *  };
 *
 */
import React from 'react';
import tinyMCE from 'tinymce';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'legacy/datepicker'.
import Datepicker from 'legacy/datepicker';
import $ from 'legacy/jquery';
import { Validate } from 'legacy/validation';

const ENTER_KEY_CODE = 13;

type Options = {
  noPencilIcon?: boolean;
  initializeDisplayValue?: boolean;
  displayValue?: () => string;
  autoCollapse?: boolean;
  defaultText?: string;
  initializeFieldValue?: boolean;
  datepicker?: boolean;
  minDate?: Date;
  maxDate?: Date;
  isHtml?: boolean;
  collapseWithQuotes?: boolean;
  validateWith?: () => boolean | { isValid: () => boolean };
  allowBlank?: boolean;
  blankMessage?: string;
};

const editableFieldControl = function (
  selector: string | JQuery,
  opts: Options = {}
) {
  let editButton;
  opts = opts || {};
  opts = $.extend(
    {
      autoCollapse: true,
    },
    opts
  );

  const container = $(selector);
  const editableField = container.find('.editable-field');
  if (opts.noPencilIcon === true) {
    editButton = container.find('.collapsed-field-text');
  } else {
    editButton = container.find('.inline-edit-icon');
    editButton.attr('data-loaded', true);
  }
  const saveButton = container.find('.save-field');
  const collapsedField = container.find('.collapsed-field');
  const collapsedText = container.find('.collapsed-field-text');

  // select2 adds a class in mutliple places.
  // We always want a field that is a form element and not a div.
  const field = container.find('.field:not(div)');

  container.on('greenhouse.collapse', collapse);

  editButton.on('click', enterEditMode);

  saveButton.unbind('click').on('click', function () {
    if (validate(opts)) {
      afterSave();
    }
  });

  if (opts.initializeDisplayValue && opts.displayValue) {
    collapsedText.text(opts.displayValue());
  }

  function afterSave() {
    let fieldText;

    if (opts.autoCollapse) {
      fieldText = collapse();
    }

    triggerUpdate(fieldText);
  }

  function collapse() {
    const field = update();

    enterReadOnlyMode();

    return field;
  }

  function enterEditMode(e: Event) {
    const existingField = $.trim(collapsedText.text());

    field.data('oldval', field.val());
    // Quotation marks indicate non-default text, so we check to make sure
    // they are editing user-generated content and not the default text
    if (
      existingField !== null &&
      existingField !== opts.defaultText &&
      opts.initializeFieldValue !== false
    ) {
      field.val(existingField.replace(/\"/g, ''));
    }

    if (opts.datepicker) {
      Datepicker.init(field);

      if (opts.minDate) {
        field.datepicker('option', 'minDate', opts.minDate);
      }

      if (opts.maxDate) {
        field.datepicker('option', 'maxDate', opts.maxDate);
      }
    }

    collapsedField.hide();
    editableField.show();

    if (!field.is(':file')) {
      field.trigger('focus');
    }

    if (!field.is('textarea')) {
      field.select();

      field.unbind('keydown').on('keydown', function (e: React.KeyboardEvent) {
        if (e.which === ENTER_KEY_CODE && validate(opts)) {
          afterSave();

          // prevent form submit if the field is within a form
          return false;
        }
      });
    }

    e.stopPropagation();
  }

  function enterReadOnlyMode() {
    editableField.hide();
    collapsedField.show();
  }

  function update() {
    let fieldText = '';
    let display;

    if (typeof opts.displayValue === 'function') {
      if (opts.isHtml) {
        fieldText = $('<div/>').html(opts.displayValue()).text();
        collapsedText.html(opts.displayValue());
      } else {
        fieldText = opts.displayValue();
        collapsedText.text(opts.displayValue());
      }
    } else {
      trimFields();

      if (fieldsEmpty()) {
        clear();
      } else {
        if (typeof tinyMCE !== 'undefined') {
          tinyMCE.triggerSave();
        }

        display = fieldText =
          opts && opts.collapseWithQuotes
            ? '"' + field.val() + '"'
            : field.val();

        if (field.is('select')) {
          const options = field.find('option:selected');
          const names = $.map(options, function (option: Array<JQuery>) {
            return $(option).text();
          });
          display = names.join(', ');
        }
        collapsedText.text(display);
      }
    }

    if (typeof tinyMCE !== 'undefined') {
      tinyMCE.triggerSave();
    }

    return fieldText;
  }
  [];
  function triggerUpdate(fieldText: string | undefined) {
    container.trigger('editableFieldUpdated', [fieldText]);
  }

  function trimFields() {
    field.each(function (this: JQuery) {
      if ($.trim($(this).val()).length === 0) {
        $(this).val('');
      }
    });
  }

  function fieldsEmpty() {
    let empty = true;

    field.each(function (this: JQuery) {
      if ($(this).val().length > 0) {
        empty = false;
      }
    });

    return empty;
  }

  function clear() {
    // Default text is optional, if not provided, an empty collapsed state will appear
    if (opts && typeof opts.defaultText !== 'undefined') {
      collapsedText.text(opts.defaultText);
    } else {
      collapsedText.text('');
    }
  }

  function validate(opts: Options) {
    if (typeof tinyMCE !== 'undefined') {
      tinyMCE.triggerSave();
    }

    Validate.clearError(field);

    if (opts && typeof opts.validateWith === 'function') {
      const result = opts.validateWith.call(field);
      // @ts-expect-error - TS2339 result mistype
      return typeof result.isValid === 'function'
        ? // @ts-expect-error - TS2339 result mistype
          result.isValid()
        : // @ts-expect-error - TS2345 result mistype
          Validate.isValid(result);
    } else if (
      opts &&
      opts.allowBlank === false &&
      !Validate.notBlank(field.val())
    ) {
      // @ts-expect-error - TS2345 blankMessage is not required
      Validate.setError(field, opts.blankMessage);
      return false;
    }

    return true;
  }
};

export default editableFieldControl;
