// eslint-disable-next-line no-restricted-imports
import _ from 'underscore';
import $ from 'legacy/jquery';
import t from 'shared/utils/translation';

const Tree = (function () {
  // eslint-disable-next-line no-shadow
  function Tree(selector, html, options) {
    this.container = $(selector);
    this.options = options || {};

    if (html) {
      this.container.html(html);
    }

    this.registerEvents();
    this.initializeSelectAll();
    this.checkBranchIfAllLeafSelected();

    if (this.options.expand_all_branches) {
      this.expandAllBranches();
    } else {
      this.expandIncompleteBranches();
    }
  }

  Tree.prototype.registerEvents = function () {
    const tree = this;
    const container = this.container;
    const options = this.options;

    container.find('.toggle').on('click', function () {
      $(this).closest('.branch').toggleClass('branch-open');
    });

    container.find('input[type=checkbox]').on('change', function () {
      const li = $(this).closest('li');
      const checked = $(this).is(':checked');

      if (li.hasClass('branch')) {
        li.find('input[type=checkbox]').prop('checked', checked);
      }

      $(this)
        .parents('.branch')
        .each(function () {
          checkIfAllLeafSelected(this);
        });

      tree.initializeSelectAll();

      if (options.onChange) {
        options.onChange.call(container[0]);
      }
    });

    container.find('.select-all').on('click', function () {
      if (tree.allSelected()) {
        tree.deselectAll();
      } else {
        tree.selectAll();
      }

      tree.initializeSelectAll();

      if (tree.options.onChange) {
        tree.options.onChange.call(tree.container[0]);
      }
    });
  };

  Tree.prototype.selectAll = function () {
    this.container.find('input[type=checkbox]').prop('checked', true);
  };

  Tree.prototype.deselectAll = function () {
    this.container.find('input[type=checkbox]').prop('checked', false);
  };

  Tree.prototype.selectedLeafCount = function () {
    return this.container.find('.leaf').find('> input:checked').length;
  };

  // Expands branches which have some children checked, but not all.
  Tree.prototype.expandIncompleteBranches = function () {
    this.container.find('.branch').each(function () {
      expandIfIncomplete($(this));
    });
  };

  Tree.prototype.expandAllBranches = function () {
    this.container.find('.branch').addClass('branch-open');
  };

  Tree.prototype.checkBranchIfAllLeafSelected = function () {
    this.container.find('li.branch').each(function () {
      checkIfAllLeafSelected($(this));
    });
  };

  Tree.prototype.initializeSelectAll = function () {
    const link = this.container.find('.select-all').find('a');

    if (this.allSelected()) {
      link.text(t('shared.tree.deselect_all'));
    } else {
      link.text(t('shared.tree.select_all'));
    }
  };

  Tree.prototype.allSelected = function () {
    const checkboxes = this.container.find(
      '> .tree > li > input[type=checkbox]'
    );
    return checkboxes.length === checkboxes.filter(':checked').length;
  };

  // Returns the outermost selected branch IDs (i.e. omits branch IDs which are implied
  // by its parent already being selected).
  Tree.prototype.getSelectedBranchIds = function () {
    return branchNodeIds(this.container);
  };

  // Returns the IDs of selected leaf nodes that don't have a parent that is selected
  // (i.e. omits leaf IDs which are implied by its parent branch being selected).
  Tree.prototype.getSelectedLeafIds = function () {
    const checkedNodes = this.container.find('li.leaf').filter(function () {
      const checked = $(this).find('> input[type=checkbox]').is(':checked');
      const parent = $(this).closest('li.branch');
      const parentChecked = parent
        .find('> input[type=checkbox]')
        .is(':checked');

      return checked && !parentChecked;
    });

    return _(checkedNodes).map(function (node) {
      return $(node).data('node-id');
    });
  };

  Tree.prototype.getAllSelectedLeafIds = function () {
    const checkedNodes = this.container.find('li.leaf').filter(function () {
      return $(this).find('> input[type=checkbox]').is(':checked');
    });

    return _(checkedNodes).map(function (node) {
      return $(node).data('node-id');
    });
  };

  function branchNodeIds(node) {
    if (node.find('> input[type=checkbox]').is(':checked')) {
      return [node.data('node-id')];
    }

    let ids = [];

    node.find('> .tree > li.branch').each(function (i, child) {
      ids = ids.concat(branchNodeIds($(child)));
    });

    return ids;
  }

  // NOTE: this doesn't handle multiple levels of nesting
  function expandIfIncomplete(node) {
    const checked = node.find('> input[type=checkbox]').is(':checked');

    if (!checked) {
      const anyChildrenChecked =
        node.find('input[type=checkbox]:checked').length > 0;

      if (anyChildrenChecked) {
        node.addClass('branch-open');
      }
    }
  }

  function checkIfAllLeafSelected(node) {
    const branch = $(node);
    const checkbox = branch.find('> input[type=checkbox]');
    const childCheckboxes = branch.find('.leaf > input[type=checkbox]');

    checkbox.prop(
      'checked',
      childCheckboxes.filter(':checked').length === childCheckboxes.length
    );
  }

  return Tree;
})();

export default Tree;
