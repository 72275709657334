// eslint-disable-next-line no-restricted-imports
import _ from 'underscore';
import Handlebars from 'legacy/handlebars';
import { contentSecurityPolicy } from 'legacy/util/prevent_default_placeholder_anchor_tags';
import DropboxChooser from './dropbox_chooser';
import GoogleDrivePicker from './google_drive_picker';
import S3DirectUploader from './s3_direct_uploader';
import $ from 'legacy/jquery';
import { Validate } from 'legacy/validation';
import t from 'shared/utils/translation';
import ResumeParser from '../../add_person/resume_parser';

const is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
const is_safari = navigator.userAgent.indexOf('Safari') > -1 && !is_chrome;

const FormControl = {
  initFileChooser: function (fileType) {
    $('#' + fileType + '_button').click(function () {
      FormControl.attachFileButton(fileType);
    });
  },

  initFilenameDisplayWithValidation: function (
    fileType,
    validateFunc,
    validateMessage,
    afterSuccess,
    afterError
  ) {
    $('#' + fileType + '_chooser').change(function () {
      FormControl.validateAndSetFilename(
        fileType,
        validateFunc,
        validateMessage,
        afterSuccess,
        afterError
      );
    });
  },

  /**
   * Cross-browser support for file upload
   */
  attachFileButton: function (file_type) {
    if (is_safari) {
      this.initFileChooserForSafari(file_type);
    } else {
      $('#' + file_type + '_chooser').trigger('click');
    }

    // clear and hide the paste textarea
    $('#' + file_type).val('');
    $('#' + file_type + '_paste').hide();
  },

  initFileChooserForSafari: function (file_type) {
    const chooser = $('#' + file_type + '_chooser');

    // For "security reasons" safari does not allow for the input file HTML control
    // to be triggered programatically.  We replace the button with a standard
    // HTML file control if the user is on safari.
    const standardFileEl = Handlebars.compile(
      '<div class="{{alt_css_class}}"></div>'
    );
    const alt_css_class = 'alt-upload-' + file_type.replace(/_/g, '-');
    const actions = $('#' + file_type + '_actions');

    actions.after(standardFileEl({ alt_css_class: alt_css_class }));
    $('.' + alt_css_class).append(chooser.detach());
    chooser.show();
    actions.hide();
  },

  getFilenameFromPath: function (path) {
    return path.substr(path.lastIndexOf('\\') + 1);
  },

  validateAndSetFilename: function (
    fileType,
    validateFunc,
    validateMessage,
    afterSuccess,
    afterError
  ) {
    const chooser = $('#' + fileType + '_chooser');
    const filename = FormControl.getFilenameFromPath(chooser.val());

    if (
      Validate.field('#' + fileType + '_chooser', validateFunc, validateMessage)
    ) {
      $('#' + fileType + '_filename').html(filename);

      if (!is_safari) {
        $('#' + fileType + '_filename_container').show();
      }

      if (typeof afterSuccess === 'function') {
        afterSuccess();
      }
    } else {
      FormControl.clearFileChooserAndFilename(fileType);
      if (typeof afterError === 'function') {
        afterError();
      }
    }
  },

  clearFileChooserAndFilename: function (fileType) {
    FormControl.clearFileChooser('#' + fileType + '_chooser');
    $('#' + fileType + '_filename').html('');
    $('#' + fileType + '_filename_container').hide();
    $('#' + fileType + '_url').val('');
  },

  clearFileChooser: function (selector) {
    const input = $(selector);
    input.val(''); // this takes care of non-IE browsers
    const clone = input.clone(true);
    input.replaceWith(clone); // this takes care of IE
    return clone;
  },

  /**
   *
   * Registers events for a control with "attach file or paste text"
   * functionality.
   */
  attachOrPaste: function (selector, options) {
    options = options || {};

    const fnUploadStarted = options.uploadStarted || function () {};
    const fnUploadFinished = options.uploadFinished || function () {};
    const fnAttachmentRemoved = options.attachmentRemoved || function () {};
    const resumeParser = options.resumeParser;

    $(selector).each(function () {
      const $container = $(this);
      const $googlePickerTrigger = $container.find(
        '[data-source="google-drive"]'
      );
      const $linkContainer = $container.find('.link-container');
      const $messages = $container.find('[data-messages="uploaders"]');
      const field = $container.data('field');
      const model = $container.data('model');
      let s3DirectUploader;

      function setup() {
        $container
          .find('[data-source="paste"]')
          .on('click', togglePasteArea)
          .on('click', contentSecurityPolicy.hrefPreventDefault);
        if (allowAdvancedControls($container)) {
          setupDirectUploader();
          setupGoogleDrivePicker();
        } else {
          $googlePickerTrigger.remove();
        }
        setupDropbox();
      }

      function setupGoogleDrivePicker() {
        new GoogleDrivePicker({
          $trigger: $googlePickerTrigger,
          $messages: $messages,
          uploadCancelled: uploadCancelled,
          uploadComplete: uploadComplete,
          uploadFinished: uploadFinished,
          uploadStarted: uploadStarted,
          uploadToS3: uploadToS3,
        });
      }

      function setupDirectUploader() {
        s3DirectUploader = new S3DirectUploader({
          $form: $(jQueryEscape('#s3_upload_for_' + field)),
          $trigger: $container.find('[data-source="attach"]'),
          $dropZone: $container.find('.drop-zone'),
          $progressBar: $container.find('.progress-bar'),
          $fileTypes: $container.data('file-types'),
          uploadCancelled: uploadCancelled,
          uploadComplete: uploadComplete,
          uploadFailed: uploadFailed,
          uploadStarted: uploadStarted,
        });
      }

      function setupDropbox() {
        new DropboxChooser({
          $trigger: $container.find('[data-source="dropbox"]'),
          $messages: $messages,
          uploadComplete: uploadComplete,
          uploadCancelled: uploadCancelled,
          uploadStarted: uploadStarted,
        });
      }

      function uploadToS3(file) {
        s3DirectUploader.uploadFile(file);
      }

      function togglePasteArea() {
        $(this).toggleClass('active');
        $container.find('textarea.paste').toggle();
        $(document).trigger('greenhouse.jobApplication.heightChanged');
      }

      function uploadStarted() {
        $container.find('textarea.paste').hide();
        $linkContainer.hide();
        fnUploadStarted();
      }

      function uploadFinished() {
        $messages.hide();
        fnUploadFinished(field);
      }

      function uploadComplete(content) {
        if (resumeParser) {
          const data = {
            resume: {
              filename: content.filename,
              resume_url: content.url,
              from_s3: true,
            },
          };

          new ResumeParser(resumeParser.fromResume).post(data);
        } else {
          FormControl.attachFromUrl(
            $container.closest('form'),
            model,
            field,
            content.filename,
            content.url,
            fnAttachmentRemoved
          );
          uploadFinished();
        }
      }

      function uploadCancelled() {
        $messages.hide();
        FormControl.clearFileChooserAndFilename(model);
        $linkContainer.show();
        fnUploadFinished(field);
      }

      function uploadFailed(event, errorJson) {
        const message = t('add_person.flash_messages.expired', {});
        $linkContainer.addClass('error').text(message);
        uploadCancelled();
      }

      /*  Some browsers, like IE9, disallow javascript actions on file choosers for security reasons.  In these cases,
          We need to display a file chooser in our form that the user clicks on explicitly.  This won't work for S3
          Direct Upload because the s3_direct_upload requires its own form so it can POST to Amazon AWS.

          In these cases, we just fall back to the old file chooser method where the file data is sent to Greenhouse
          with the rest of the candidate data.
       */
      function allowAdvancedControls($container) {
        if (allowS3($container)) {
          return true;
        } else {
          const $attachLink = $container.find('[data-source="attach"]');
          $attachLink
            .on('click', injectFileChooser)
            .on('click', contentSecurityPolicy.hrefPreventDefault);
          return false;
        }
      }

      function injectFileChooser() {
        $linkContainer.empty();

        let $fileChooser = $("<input type='file' />")
          .attr('id', field + '_file')
          .attr('name', model + '[' + field + ']')
          .appendTo($linkContainer);
        const fileTypes = $fileChooser
          .closest('.attach-or-paste')
          .data('file-types');

        $fileChooser.on('change', function () {
          if (
            $fileChooser.val() &&
            !Validate.specificFileFormat(fileTypes)($fileChooser.val())
          ) {
            const message = t('add_person.flash_messages.invalid_format', {
              allowed_formats: fileTypes.join(', '),
            });

            alert(message);
            $fileChooser.replaceWith(($fileChooser = $fileChooser.clone(true)));
          }

          uploadFinished();
        });
      }

      /* Escapes square brackets to allow finding by IDs that have brackets in them */
      function jQueryEscape(selector) {
        return selector.replace(/\[/, '\\[').replace(/\]/, '\\]');
      }

      function allowS3(container) {
        return container.data('allowS3');
      }

      setup();
    });
  },

  registerFileChooser: function (attachmentWrapper) {
    const fileInput = $(attachmentWrapper).next('input[type=file]');
    const clearInput = fileInput.next('input[type=hidden].clear-attachment');
    const removeFileButton = $(attachmentWrapper).find('.x');

    removeFileButton.on('click', clear);

    function clear() {
      const attachmentId = $(this).parent().attr('data-id');

      clearInput.val(_.compact([clearInput.val(), attachmentId]).join(','));
      fileInput.show();
      $(this).parent().remove();
    }
  },

  // eslint-disable-next-line max-params
  attachFromUrl: function (
    $form,
    model,
    fileType,
    filename,
    url,
    fnAttachmentRemoved
  ) {
    const urlName = fileType + '_url';
    let $hiddenUrl = $('#' + urlName);
    let $hiddenUrlFilename = $('#' + urlName + '_filename');
    const $container = $('#' + fileType + '_chosen');
    const $linkContainer = $container.parent().find('.link-container');
    const $removeLink = $container.find('a.remove');
    const fileTypes = $linkContainer
      .closest('.attach-or-paste')
      .data('file-types');

    if (fileTypes) {
      if (!Validate.specificFileFormat(fileTypes)(filename)) {
        const message = t('add_person.flash_messages.invalid_format', {
          allowed_formats: fileTypes.join(', '),
        });

        alert(message);
        removeAttachment();
        return false;
      }
    }

    if ($hiddenUrl.length === 0) {
      $hiddenUrl = $("<input type='hidden' />")
        .attr('id', urlName)
        .attr('name', model + '[' + urlName + ']')
        .appendTo($form);

      $hiddenUrlFilename = $("<input type='hidden' />")
        .attr('id', urlName + '_filename')
        .attr('name', model + '[' + urlName + '_filename]')
        .appendTo($form);
    }

    $container.on('click', 'a.remove', removeAttachment);
    $removeLink.css('display', 'inline-block');

    FormControl.clearFileChooserAndFilename(fileType);
    $hiddenUrl.val(url);
    $hiddenUrlFilename.val(filename);

    $form.find("div[data-field='" + fileType + "'] .link-container").hide();
    $container.show();
    $removeLink.css('display', 'inline-block');

    $('#' + fileType + '_text').hide();
    $('#' + fileType + '_filename').text(filename);

    function removeAttachment() {
      FormControl.clearFileChooserAndFilename(fileType);
      $container.hide();
      $removeLink.hide();
      $linkContainer.show();
      $hiddenUrl.remove();
      $hiddenUrlFilename.remove();

      fnAttachmentRemoved();
    }
  },

  useUploadResumeChooser: function (filename, url) {
    const $container = $("div[data-field='resume']");

    $container.find("[name='person[resume]']").remove();

    $container.find('.link-container').hide();
    $('#resume_filename').text(filename);
    $('#resume_chosen').show();
    $('#resume_text').hide();

    FormControl.attachFromUrl(
      $container.closest('form'),
      'person',
      'resume',
      filename,
      url,
      function () {}
    );
  },
};

export default FormControl;
