import angular from 'angular';

export default function bootstrapModule(
  elementName: string,
  component: string
) {
  const element = angular.element('[ng-module="' + elementName + '"]');
  if (element.length > 0) {
    angular.bootstrap(element, [component], {
      strictDi: true,
    });
  }
}
