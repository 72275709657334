import Taggable from 'legacy/taggable';
import { ITimeoutService } from 'angular';

/**
 * A thin wrapper over our own Taggable module.  For AngularJS apps using the Angular UI
 * router, care must be taken when Taggable is initialized.  The $viewContentLoaded event is
 * emitted before the HTML is loaded.  Therefore, we need to wait some extra time in order
 * to attach the event handlers properly.
 */
export default function TaggableService($timeout: ITimeoutService) {
  return {
    init: function () {
      $timeout(function () {
        Taggable.init();
      }, 0);
    },
    latestFields: Taggable.latestFields,
  };
}
