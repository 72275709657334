const existingJQuery = (window as any).$;

const $ = existingJQuery || require('./jquery');

if (!existingJQuery) {
  (window as any).$ = (window as any).jQuery = $;
  // eslint-disable-next-line max-len
  require('imports-loader?type=commonjs&wrapper=window&additionalCode=var%20define%20=%20false;%20var%20exports%20=%20false;!legacy/3rdparty/chosen.jquery');
  require('legacy/3rdparty/select2-v3.5.2.min');
  // requires jQuery to be loaded; set `this` to window for backwards compatibility
  // eslint-disable-next-line max-len
  require('imports-loader?type=commonjs&wrapper=window&additionalCode=var%20define%20=%20false;%20var%20exports%20=%20false;!legacy/jquery/jquery-ui.min');
  // eslint-disable-next-line max-len
  require('imports-loader?type=commonjs&wrapper=window&additionalCode=var%20define%20=%20false;%20var%20exports%20=%20false;!legacy/jquery/jquery.form');
  // eslint-disable-next-line max-len
  require('imports-loader?type=commonjs&wrapper=window&additionalCode=var%20define%20=%20false;%20var%20exports%20=%20false;!legacy/jquery/jquery.numeric');
  // eslint-disable-next-line max-len
  require('imports-loader?type=commonjs&wrapper=window&additionalCode=var%20define%20=%20false;%20var%20exports%20=%20false;!legacy/jquery/jquery.placeholder');
  // eslint-disable-next-line max-len
  require('imports-loader?type=commonjs&wrapper=window&additionalCode=var%20define%20=%20false;%20var%20exports%20=%20false;!legacy/jquery/jquery.tinysort');
  // eslint-disable-next-line max-len
  require('imports-loader?type=commonjs&wrapper=window&additionalCode=var%20define%20=%20false;%20var%20exports%20=%20false;!legacy/jquery/jquery.typewatch');
  // eslint-disable-next-line max-len
  require('imports-loader?type=commonjs&wrapper=window&additionalCode=var%20define%20=%20false;%20var%20exports%20=%20false;!legacy/jquery/readmore');
  require('./jquery_ujs');
  require('legacy/3rdparty/jquery-textntags');
  // eslint-disable-next-line max-len
  require('imports-loader?type=commonjs&wrapper=window&additionalCode=var%20define%20=%20false;%20var%20exports%20=%20false;!blueimp-file-upload/js/jquery.fileupload');
  require('../../mobile/components/gh_mobile_flyout');
  require('../../mobile/components/gh_mobile_tabs');
}

require('./contains_insensitive');

export default $;
