import Handlebars from 'legacy/handlebars';
import $ from 'legacy/jquery';

const moreInfoFlyoutTemplate = Handlebars.compile(
  '<div class="more-info-flyout"><span class="arrow"></span><div>{{{desc}}}</div></div>'
);

const MoreInfo = {
  // selector is an optional parent element to limit the initialization to
  init: function () {
    $('.more-info-link').unbind('hover').hover(showFlyout, hideFlyout);

    function showFlyout(this: JQuery<HTMLElement>) {
      const desc = $(this).data('desc');

      if (desc) {
        const flyout = $(moreInfoFlyoutTemplate({ desc: desc }));
        const link = $(this);

        $('body').append(flyout);

        flyout.css({
          top: link.offset().top - flyout.outerHeight() - 10,
          left:
            link.offset().left +
            link.outerWidth() / 2 -
            flyout.outerWidth() / 2,
        });

        setTimeout(function () {
          flyout.show();
        }, 100);
      }
    }

    function hideFlyout() {
      $('.more-info-flyout').remove();
    }
  },
};

export default MoreInfo;
