// eslint-disable-next-line no-restricted-imports
import _ from 'underscore';
// @ts-expect-error - TS7016
import AjaxSelectBox from 'legacy/ajax_select_box';
import $ from 'legacy/jquery';
import t from 'shared/utils/translation';

const AsyncOrganizationalStructureControl = (function () {
  // eslint-disable-next-line no-shadow
  const AsyncOrganizationalStructureControl = function (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this: any,
    id: string,
    options: unknown
  ) {
    this.id = id;
    this.$select = $(id);
    this.type = this.kind();
    this.orgStructureOptions = options || {};
    this.options = this.mapToAjaxOptions();
    new AjaxSelectBox(this.options);
  };

  AsyncOrganizationalStructureControl.prototype.prop = function (
    prop: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValue: any
  ) {
    return this.orgStructureOptions.hasOwnProperty(prop)
      ? this.orgStructureOptions[prop]
      : defaultValue;
  };

  AsyncOrganizationalStructureControl.prototype.kind = function () {
    return this.$select.data('type');
  };

  AsyncOrganizationalStructureControl.prototype.mapToAjaxOptions = function () {
    return {
      selectBox: this.$select,
      pagination: true,
      url: this.url(),
      callback: this.orgStructureOptions.onChangeCallback,
      defaultOption: this.prop('defaultOption', undefined),
      config: {
        width: this.prop('width', '300px'),
        placeholder: this.placeholder(),
        initSelection: this.prop('initSelection', this.initSelection),
        allowClear: this.prop('allowClear', true),
        containerCss: this.prop('containerCss', undefined),
        formatSearching: this.searchText(),
      },
    };
  };

  AsyncOrganizationalStructureControl.prototype.selectedText = function () {
    const data = this.$select.select2('data');

    if (!data) {
      return 'None';
    } else if (Array.isArray(data)) {
      return _.map(data, function (obj) {
        return obj.text;
      });
    } else if (data.hasOwnProperty('text')) {
      return data.text;
    } else {
      return '';
    }
  };

  AsyncOrganizationalStructureControl.prototype.placeholder = function () {
    if (this.orgStructureOptions.placeHolder) {
      return this.orgStructureOptions.placeHolder;
    } else {
      return this.allowClear() ? 'No ' + this.type : 'Select one';
    }
  };

  AsyncOrganizationalStructureControl.prototype.searchText = function () {
    return this.$select.attr('searchText') || t('select.searchText');
  };

  AsyncOrganizationalStructureControl.prototype.allowClear = function () {
    return this.prop('allowClear', true);
  };

  AsyncOrganizationalStructureControl.prototype.url = function () {
    const path = this.$select.data('url');
    return (
      path +
      '?' +
      [this.hiringPlanIdParam(), this.includeSelf(), this.disableIds()].join(
        '&'
      )
    );
  };

  AsyncOrganizationalStructureControl.prototype.hiringPlanIdParam =
    function () {
      const id = this.$select.data('hiringPlanId');
      const isNew = this.$select.data('new');

      if (id) {
        return 'hp_id=' + id;
      } else if (isNew) {
        return 'new=true';
      } else {
        return '';
      }
    };

  AsyncOrganizationalStructureControl.prototype.disableIds = function () {
    const ids = this.$select.data('disableIds');

    if (ids) {
      return ids
        .map(function (id: string) {
          return 'disabled_ids[]=' + id;
        })
        .join('&');
    } else {
      return '';
    }
  };

  AsyncOrganizationalStructureControl.prototype.includeSelf = function () {
    const includeSelf = this.$select.data('includeSelf');

    // default to true
    if (includeSelf || includeSelf === undefined) {
      return 'include_self=true';
    } else {
      return '';
    }
  };

  AsyncOrganizationalStructureControl.prototype.initSelection = function (
    el: HTMLElement,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (params: any) => void
  ) {
    const $element = $(el);

    const value = $element.attr('value');
    const valueText =
      $element.attr('value_text') || $element.data('value-text');

    const defaultValues = $element.attr('default_values');

    if (value && valueText) {
      callback({ id: value, text: valueText });
    } else if (defaultValues) {
      callback(JSON.parse(defaultValues));
    }
  };

  AsyncOrganizationalStructureControl.linkToAllCheckbox = function (
    checkboxSelector: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    orgStructureControl: any
  ) {
    const $checkbox = $(checkboxSelector);
    const $multiSelectInput = orgStructureControl.$select;

    // When checkbox for all offices or all departments is checked,
    // disable multi select input field.
    $checkbox.on('change', function () {
      if ($checkbox.is(':checked')) {
        $multiSelectInput.val([]);
        $multiSelectInput.select2('enable', false);
        $multiSelectInput.trigger('change');
      } else {
        $multiSelectInput.select2('enable', true);
      }
    });

    // When a multi select option is selected,
    // uncheck the all offices or all departments $checkbox.
    $multiSelectInput.on('change', function () {
      if ($checkbox.is(':checked') && $multiSelectInput.val().length > 0) {
        $checkbox.prop('checked', false);
      }
    });
  };

  AsyncOrganizationalStructureControl.prototype.selectedIdsAndChildrenIdsArray =
    function () {
      return _.uniq(
        _.flatten(
          this.$select
            .select2('data')
            .map(function (e: { id: string; id_path: string }) {
              return [e.id, e.id_path];
            })
        )
      );
    };

  return AsyncOrganizationalStructureControl;
})();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default AsyncOrganizationalStructureControl as any;
