import $ from 'legacy/jquery';

// For cases where we have href="#", we want to preventDefault so that the
// page does not scroll to the top of the page.
// This method is used in cases where we are unbinding the click event
// and we want to re-bind the behavior to preventDefault on click events.
// This is currently primarily used in the modal.js and ajax_modal.js
// This method was a necessary addition as part of the project to remove all
// references to href="javascript;" because of our Content Security Policy implementation.

export const contentSecurityPolicy = {
  hrefPreventDefault: function (e: Event) {
    e.preventDefault();
  },
};

export const preventDefaultPlaceholderAnchorTags = () => {
  $(document).on(
    'click',
    'a[href="#"]',
    contentSecurityPolicy.hrefPreventDefault
  );
};
