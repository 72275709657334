import _ from 'underscore';
import $ from 'legacy/jquery';
import { IScope, ITimeoutService } from 'angular';

interface SortableScope extends IScope {
  method?: (params: { order: Array<string> }) => void;
}

export default function ghSortable($timeout: ITimeoutService) {
  return {
    restrict: 'A',
    scope: {
      method: '&',
    },
    require: '?ngModel',
    link: function (
      scope: SortableScope,
      element: any,
      attr: any,
      ngModelCtrl: any
    ) {
      const handle = attr.handle;

      $timeout(function () {
        if (handle) {
          $('li').prepend('<span class="' + handle + '"></span>');
        }

        $(element).sortable({
          handle: handle ? '.' + handle : null,
          stop: function () {
            var order = _.map($(this).find('li'), function (li) {
              return $(li).attr('key');
            });

            if (ngModelCtrl) {
              scope.$apply(function () {
                ngModelCtrl.$setViewValue(order);
              });
            }

            if (scope.method) {
              scope.method({ order: order });
              scope.$apply();
            }
          },
        });
      }, 1);
    },
  };
}
