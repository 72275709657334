import $ from 'legacy/jquery';
import t from 'shared/utils/translation';

/**
 * SelectBox - Use ajax to load the contents of a select2 widget.
 *
 * Arguments:
 * - selectBox (jQuery element)        - jQuery element to which to attach the select2 widget.
 * - url (String, optional)            - JSON Data source endpoint. Can instead be specified as a data attribute
 *                                       It should return a payload
 *                                       like: {
 *                                         "results": [
 *                                           { id: ID1, text: TEXT1 },
 *                                           { id: ID2, text: TEXT2 },
 *                                           ....
 *                                         ],
 *                                         "more": true/false
 *                                       }
 * - callback (function, optional)     - When an item is selected, run the callback. It will
 *                                       receive an event object as its first argument.
 * - defaultOption (hash, optional)    - When the select2 widget is initialized, load it with
 *                                       this item. It can also accept options from data attribute
 *                                       default-option
 *                                       e.g.
 *                                           <input
                                               type="hidden"
                                               id="user_id"
                                               name="user_id"
                                               data-default-option="{ 'id' : 1, 'text' : 'Foo' }"
                                               data-ajax-url="/reports/recruiting_update/users" />

 * - debounceDelay (integer, optional) - (In Millis). How long to wait before issuing request
 *                                       for results.
 * - config                            - Any additional configuration to pass to select2()
 * - multiple (boolean, optional)      - Multiple options can be selected. Default to false.
 *                                        Can also be specified as a data attribute
 * - allowFreeText (boolean, optional) - User can enter a free text value. Default to false.
 *                                        Can also be specified as a data attribute
 * - pagination (boolean, optional)    - Set this to false if the server endpoint does not support pagination.
 *                                        This will assume everything is loaded on the first ajax call.
 *                                        After that, the search term will only check what's already loaded and
 *                                        not make another ajax request. Defaults to true.
 *
 * You can instantiate this class like:
 *
 *   new AjaxSelectBox({
 *     url: '/path/to/results',
 *     selectBox: $('#find_me'),
 *     callback: function(e) { doSomethingCoolWithThis(); },
 *     defaultOption: { id: 'myId', text: 'Hello, Ajaxy World!' },
 *     config: { width: '100%' }
 *     multiple: true,
 *     allowFreeText: true
 *   });
 *
 * for an HTML element defined like:
 *
 *   <input type="hidden" id="find_me" data-url="/some/data/source/endpoint" data-multiple="true" />
 *
 */
const AjaxSelectBox = function (opts) {
  const callback = opts.callback;
  const ajaxError = opts.error;
  const debounceDelay = opts.debounceDelay || 500;
  const $selectBox = opts.selectBox;
  const url = opts.url || opts.selectBox.data('url');
  const config = opts.config || opts.selectBox.data('config') || {};
  const multiple = opts.multiple || opts.selectBox.data('multiple');
  const allowFreeText =
    opts.allowFreeText || opts.selectBox.data('allow-free-text');
  const searchText =
    opts.searchText ||
    opts.selectBox.data('search-text') ||
    t('select.searchText');
  const noResultsText =
    opts.noResultsText ||
    opts.selectBox.data('no-results-text') ||
    t('select.noResultsText');
  const pagination = opts.pagination !== false;
  const cacheDataSource = {};
  let timeout;

  function queryAndCache(params) {
    const page = params.page;
    const term = params.term;
    const key = pagination ? [term, page] : 'results';
    const data = pagination ? { term: term, page: page } : null;

    $.ajax({
      url: url,
      data: data,
      success: function (response) {
        cacheDataSource[key] = response;
        params.callback(response);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        ajaxError(xhr, ajaxOptions, thrownError);
      },
    });
  }

  function query(params) {
    const key = pagination ? [params.term, params.page] : 'results';
    let cachedData = cacheDataSource[key];

    if (cachedData) {
      if (!pagination) {
        // So updating the children by searching doesn't change the cached data
        const clonedResult = JSON.parse(JSON.stringify(cachedData.results));

        cachedData = Object.assign(
          {},
          { results: filterLocal(clonedResult, params.term.toLowerCase()) }
        );
      }

      params.callback(cachedData);
    } else {
      window.clearTimeout(timeout);
      timeout = window.setTimeout(function () {
        queryAndCache(params);
      }, debounceDelay);
    }
  }

  function setupSelectBox() {
    $selectBox.select2(
      Object.assign(
        {
          query: query,
          multiple: multiple,
          createSearchChoice: createSearchChoice,
          formatSearching: searchText,
          formatNoMatches: noResultsText,
        },
        config
      )
    );
  }

  function createSearchChoice(term, data) {
    if (allowFreeText) {
      const termNotFound =
        $(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0;

      if (termNotFound) {
        return { id: term, text: term };
      }
    }
  }

  function filterLocal(data, term) {
    return data.filter(function (object) {
      const matchedText = object.text.toLowerCase().indexOf(term) >= 0;

      if (object.children) {
        object.children = filterLocal(object.children, term);
      }

      return matchedText || (object.children && object.children.length > 0);
    });
  }

  function addEventHandler() {
    if (typeof callback === 'function') {
      $selectBox.on('change', callback);
    }
  }

  function addDefaultData() {
    $selectBox.each(function () {
      $(this).select2(
        'data',
        opts.defaultOption || $(this).data('default-option')
      );
    });
  }

  function setup() {
    setupSelectBox();
    addEventHandler();
    addDefaultData();
  }

  setup();
};

export default AjaxSelectBox;
