import { IAttributes, IScope, ITimeoutService } from 'angular';
import $ from 'legacy/jquery';

interface DropdownScope extends IScope {
  noToggle: boolean;
  calculatePosition: () => void;
}

interface Attributes extends IAttributes {
  noToggle: boolean;
}

export default function ghDropdown($timeout: ITimeoutService) {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    link: function (scope: DropdownScope, element: any, attr: Attributes) {
      scope.noToggle = attr.noToggle;

      scope.calculatePosition = function () {
        $(element).find('.accordion-child').hide();
      };

      $timeout(function () {
        scope.calculatePosition();

        $(element)
          .find('.dropdown-toggle')
          .on('click', function (e: any) {
            e.stopPropagation();
          });

        $(element)
          .find('.accordion-parent')
          .on('click', function (this: any, e: any) {
            var currentElement = $(this).next(),
              currentlyOpen = currentElement.is(':visible');

            $(element).find('.accordion-child').slideUp();

            if (!currentlyOpen) {
              while (
                currentElement &&
                currentElement.hasClass('accordion-child')
              ) {
                currentElement.slideDown();
                currentElement = currentElement.next();
              }
            }

            e.stopPropagation();
          });
      }, 1);
    },
    template:
      "<div  uib-dropdown class='dropdown-wrap' on-toggle='calculatePosition()'>" +
      "<span class='dropdown-toggle-wrap' ng-hide='noToggle'>" +
      "<span uib-dropdown-toggle class='dropdown-toggle'></span>" +
      '</span>' +
      "<div uib-dropdown-menu class='dropdown-menu' role='menu' ng-transclude></div>" +
      '</div>',
  };
}
